<template>
    <div>
        <devOnly>
            <div
                v-if="Object.keys(errors)[0]"
                class="bg-red-800 text-white p-2"
            >
                {{ errors }}
            </div>
        </devOnly>
        <template v-for="notification in notifications">
            <div
                :class="`bg-${notification.color || stone}-800 text-white p-4 uppercase text-lg text-center`"
            >
                <strong>{{ notification.title }}:</strong>
                {{ notification.description }}
            </div>
        </template>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
        <UModal
            v-model="errorModal.show"
            :uui="{ width: 'max-w-6xl mx-12 2xl:mx-48' }"
            prevent-close
            aaaa="errorModal.error?.callback ? true : false"
        >
            <UCard
                v-if="errorModal.error"
                :ui="{
                    body: { base: '!p-6 overflow-y-auto max-h-[60vh]' },
                    header: {
                        background: `!bg-${errorModal.error?.info ? 'indigo' : 'red'}-600 dark:!bg-${errorModal.error?.info ? 'indigo' : 'red'}-800`,
                    },
                }"
            >
                <template #header>
                    <div class="modal-header-wrap">
                        <h3 v-if="errorModal.error?.info">
                            <UIcon name="i-mdi-information-variant-box"/>
                            <p>Info {{ errorModal.error.statusCode }}</p>
                        </h3>
                        <h3 v-else>
                            <UIcon name="i-mdi-bug" />
                            Error {{ errorModal.error.statusCode }} - ohh, ohhh
                        </h3>
                        <UButton
                            variant="ghost"
                            color="white"
                            icon="i-mdi-close"
                            class="modal-header-close"
                            @click="errorModal.close"
                        />
                    </div>
                </template>
                <!--<div class="min-h-[40vh]">
                    <vue-json-pretty 
                        class="!text-[12px]"
                        itemHeight="20" 
                        :data="errorModalMessage" 
                    />
                </div>
                <p class="mb-6"><u class="text-xs uppercase">Was ist passiert:</u><br>{{ [
                        errorModal.error.statusMessage, 
                        errorModal.error.error.type, 
                        errorModal.error.cause?.message || errorModal.error.cause?.error?.desc || errorModal.error.cause
                    ].filter(e=>e).join(' ... ') }}</p>
                <p v-if="errorModal.error.error.path" class="mb-6"><u class="text-xs uppercase">Was ist betroffen:</u><br>{{ errorModal.error.error.path }}</p>-->
                <p
                    v-if="
                        errorModal.error.description && !errorModal.error?.info
                    "
                    class="font-bold text-xl mb-2"
                >
                    {{ errorModal.error.description }}
                </p>
                <p
                    v-if="errorModal.error.message"
                    class="font-bold text-lg mb-2"
                >
                    {{ errorModal.error.message }}
                </p>
                <p v-if="errorModal.error.error" class="mb-4">
                    {{ errorModal.error.error }}
                </p>
                <p v-if="errorModal.error.solution" class="mb-4">
                    {{ errorModal.error.solution }}
                </p>

                <UButton
                    v-if="!errorModal.extend"
                    @click="errorModal.extend = true"
                    color="gray"
                    variant="solid"
                    size="md"
                    label="Show details"
                    class="mt-4"
                />
                <template v-if="!errorModal.error?.info && errorModal.extend">
                    <UDivider />
                    <div class="mt-4 mb-0.5 text-sm">Error object:</div>
                    <pre
                        class="whitespace-pre-wrap text-xs bg-stone-400/20 p-4 rounded-md text-left"
                        >{{ errorModal.error }}</pre
                    >

                    <template
                        v-if="errorModal.error?.stack !== '<pre></pre>'"
                        a="?.startsWith('<pre>')"
                    >
                        <div class="mt-4 mb-0.5 text-sm">Error stack:</div>
                        <pre
                            class="whitespace-pre-wrap text-xs bg-red-400/20 p-4 rounded-md"
                            v-html="errorModal.error.stack"
                        />
                    </template>
                </template>
                <div
                    v-if="errorModal.error.uuid"
                    class="text-xs mt-4 font-mono"
                >
                    <a @click="">UUID: {{ errorModal.error.uuid }}</a>
                </div>
                <template #footer>
                    <div class="flex space-x-5 justify-center sm:justify-end">
                        <UButton
                            @click="errorModal.close"
                            color="primary"
                            variant="solid"
                            label="ok, zurück"
                        />
                    </div>
                </template>
            </UCard>
        </UModal>
        <Teleport to="body">
            <div style="z-index: 99999">
                <UIcon name="i-mdi-lan-connect" class="hidden" />
                <UIcon name="i-mdi-lan-disconnect" class="hidden" />
                <UIcon name="i-mdi-close" class="hidden" />
                <UNotifications />
            </div>
            <!-- <devOnly>
                <UButton
                    :loading="processQueueStatus === 'pending'"
                    @click="processQueueRefresh"
                    class="z-[50] fixed bottom-8 right-8"
                    color="yellow"
                    variant="solid"
                    icon="i-mdi-food-processor"
                />
            </devOnly> -->
        </Teleport>
    </div>
</template>
<script setup>
import { Icon } from '@iconify/vue';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
dayjs.locale('de');

/* ROUTE LADEN */
const route = useRoute();
const router = useRouter();

const auth = await useAuth();

definePageMeta({
    layout: !auth?.value ? 'userless' : 'default',
});

const runtimeConfig = useRuntimeConfig();
const { headers } = useGlobals();
const appStore = useAppStore();
const { globals, errors } = storeToRefs(appStore);

const {
    status: processQueueStatus,
    error: processQueueError,
    refresh: processQueueRefresh,
} = useAsyncData('processQueue', () => $fetch('/api/v1/queue/process'), {
    server: false,
    immediate: false,
    transform: (e) => {
        console.log('processQueueResult', e);
        return e;
    },
});

watch(processQueueError, (error) => {
    if (error) {
        console.error('processQueueError', error);
        useError(error, "Can't processing queue");
    }
});

// useFetch('/api/v1/globals', {
//     server: false,
//     immediate: true,
//     transform: (e) => {
//         console.log('useFetch', e)
//         appStore.set('globals', e.globals)
//         appStore.set('roles', e.roles)
//         appStore.set('me.favourites', e.me?.favourites)
//         appStore.set('me.avatar', e.me?.avatar)
//         appStore.set('me.email', e.me?.email)
//         appStore.set('me.username', e.me?.username)
//         appStore.set('me.personal', e.me?.personal)
//         appStore.set('me.phone', e.me?.phone)
//         appStore.set('me.company', e.me?.company)
//         appStore.set('me.preferences', e.me?.preferences)
//         appStore.set('me.credentials', e.me?.credentials)
//         appStore.set('runtimeConfig', {
//             app: runtimeConfig.app,
//             package: runtimeConfig.public.package
//         })

//         return e
//     }
// })

const notifications = ref([]);

useHead({
    title: 'eijns',
    // meta: [
    //     { name: 'description', content: 'Enhanced Information and Joint Notification System' },
    //     { property: 'og:title', content: 'eijns' },
    // ],
    // bodyAttrs: {
    //     class: 'test'
    // },
    // script: [ { innerHTML: 'console.log(\'Hello world\')' } ]
});

// const passkeyModal = computed({
//     get: () => typeof passkeyStorage.value === 'string' ? JSON.parse(passkeyStorage.value) : passkeyStorage.value,
//     set: (val) => {

//         passkeyStorage.value = JSON.stringify({
//                 createdAt: dayjs(),
//                 version: runtimeConfig.public.package.version,
//             })
//     }
// })

// cookieConsent.value = JSON.stringify({
//         createdAt: dayjs(),
//         version: runtimeConfig.public.package.version,
//     })

const errorModal = ref({
    show: false,
    close: () => {
        //console.log('errorModal.value.error.uuid', errorModal.value.error.uuid)

        if (errorModal.value.error?.callback) {
            errorModal.value.error.callback();
        }

        nextTick(() => {
            appStore.clearError(errorModal.value.error.uuid);
            errorModal.value.show = false;
            errorModal.value.extend = false;
            errorModal.value.error = null;
        });
    },
    extend: false,
    error: null,
});

// if (process.client) {
//     // console.log('onErrorCapured')

//     onErrorCaptured((err) => {
//         // console.log('onErrorCapured', {...err}, err, )
//         //error.value.show = true
//         //error.value.data = {...err}
//         //error.value.data['cause']['url'] = window.location
//         errorModal.value.show = true
//         errorModal.value.extend = false
//         errorModal.value.error = typeof(err) === 'object' ? err : {
//             ...err
//         }
//     })
// }

watch(
    errors,
    (err) => {
        console.log('Object.keys(err)[0]', Object.keys(err)[0]);

        //nextTick(() => {
        if (err[Object.keys(err)[0]]?.data && auth?.eijnsJWT) {
            console.log('errors', err[Object.keys(err)[0]].data);
            errorModal.value.show = true;
            errorModal.value.extend = false;
            errorModal.value.error = {
                //error: err[Object.keys(err)[0]].data,
                ...err[Object.keys(err)[0]].data.error?._data,
                info: err[Object.keys(err)[0]].data.info,
                description: err[Object.keys(err)[0]].data.description,
                solution: err[Object.keys(err)[0]].data.solution,
                uuid: err[Object.keys(err)[0]].data.uuid,
                callback: err[Object.keys(err)[0]].data.callback,
            };
        }
        //})
    },
    { deep: true },
);

//if (process.client && false) {

//if (auth.eijnsJWT) {

const { error: globalsFetchError, refresh: globalsFetchRefresh } = useAsyncData(
    'globalVariables',
    () => $fetch('/api/v1/globals'),
    {
        server: false,
        immediate: true,
        transform: (e) => {
            console.log('useLazyAsyncData', e);
            appStore.set('globals', e.globals);
            appStore.set('roles', e.roles);
            appStore.set('me.favourites', e.me?.favourites);
            appStore.set('me.avatar', e.me?.avatar);
            appStore.set('me.email', e.me?.email);
            appStore.set('me.username', e.me?.username);
            appStore.set('me.personal', e.me?.personal);
            appStore.set('me.phone', e.me?.phone);
            appStore.set('me.company', e.me?.company);
            appStore.set('me.preferences', e.me?.preferences);
            appStore.set('me.credentials', e.me?.credentials);
            appStore.set('runtimeConfig', {
                app: runtimeConfig.app,
                package: runtimeConfig.public.package,
            });
            return e;
        },
    },
);

watch(globalsFetchError, (error) => {
    if (error) {
        console.error('globalsFetchError', error);
        useError(error, "Can't load global variables");
    }
});

watch(
    () => route.path,
    (val) => {
        if (val === '/home') {
            globalsFetchRefresh();
        }
    },
);

/* VUE LIFECYCLE HOOKS */
onRenderTriggered(async () => {
    // if (!globalsFetchData.value && !globalsFetchPending.value) {
    // globalsFetchRefresh()
    // }
    //document.getElementsByTagName('html')?.[0]?.classList.remove('light')
    //document.getElementsByTagName('html')?.[0]?.classList.add('dark')
    /* LOADING TIMEOUT */
    // setTimeout(() => {
    //setMainValue('loading', false)
    //////////mainStoreState.value.loading = false
    // }, Math.floor(Math.random() * (4567 - 3456 + 1) + 3456))
});

// const fetchGlobal = useLazyFetch(
//     '/api/globals',
//     {
//         method: 'GET',
//         headers: headers,
//         immediate: true,
//         server: false,
//         onResponse({ request, response, options }) {
//             //console.log('response._data', response._data)

//             if (!response.ok) {
//                 useError(response, 'Something is wrong while load globals')
//                 return
//             }

//             appStore.set('globals', response._data.globals)
//             appStore.set('roles', response._data.roles)
//             appStore.set('me.favourites', response._data.me?.favourites)
//             appStore.set('me.avatar', response._data.me.avatar)
//             appStore.set('me.email', response._data.me.email)
//             appStore.set('me.username', response._data.me.username)
//             appStore.set('me.personal', response._data.me.personal)
//             appStore.set('me.phone', response._data.me.phone)
//             appStore.set('me.company', response._data.me.company)
//             appStore.set('me.preferences', response._data.me.preferences)
//             appStore.set('me.credentials', response._data.me.credentials)
//             appStore.set('runtimeConfig', {
//                 app: runtimeConfig.app,
//                 package: runtimeConfig.public.package
//             })
//         },
//         onResponseError({ request, response, options }) {
//             useError(response, 'Can\'t load globals')
//         },
//     }
// )
//}

/*const fetchMe =  useFetch(
            '/api/user/me',
            { 
                method: 'GET',
                headers: {
                    ...headers,
                },
                immediate: true,
                server: false,
                onResponse({ request, response, options }) {
                    //console.log('response._data', response._data)

                    if (!response.ok) {
                        useError(response, 'Something is wrong while load your user data')
                        return 
                    }

                    //navigation.value = response._data
                    appStore.set('me.favourites', response._data.favourites)
                    appStore.set('me.avatar', response._data.avatar)
                    appStore.set('me.email', response._data.email)
                    appStore.set('me.username', response._data.username)
                    appStore.set('me.personal', response._data.personal)
                    appStore.set('me.phone', response._data.phone)
                    appStore.set('me.company', response._data.company)
                },
                onResponseError({ request, response, options }) {
                    useError(response, 'Can\'t load user data')
                },
            }
        )*/

/*const fetchRoles = await useFetch(
            '/api/user/roles',
            { 
                method: 'GET',
                headers: {
                    ...headers,
                },
                immediate: true,
                server: false,
                onResponse({ request, response, options }) {

                    if (!response.ok) {
                        useError(response, 'Something is wrong while loading user roles')
                        this.abort()
                    }

                    appStore.set('roles', response._data.data.map((e) => e.name))
                },
                onResponseError({ request, response, options }) {
                    useError(response, 'Can\'t load user roles')
                }
            }
        )*/
//}
//}

const colorMode = useColorMode();
//colorMode.preference = 'dark'
//console.log(colorMode.value)

const isDark = computed({
    get() {
        return colorMode.value === 'dark';
    },
    set() {
        colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark';
    },
});

let observerTimeout = null;
const toast = useToast();
const toastOffline = ref();
const toastOnline = ref();

const closeToastOnline = () => {
    if (toastOnline.value) {
        toast.remove(toastOnline.value.id); // Schließt den Offline-Toast
        toastOnline.value = null; // Setzt die Referenz zurück
    }
};

const eventOffline = () => {
    closeToastOnline();

    toastOffline.value = toast.add({
        color: 'red',
        icon: 'i-mdi-lan-disconnect',
        id: 'offline', // Eindeutige ID, um doppelte Toasts zu vermeiden
        title: 'Network status',
        description: 'You are offline',
        closeButton: { disabled: true },
        timeout: 0, // Dauer der Toast-Anzeige in Millisekunden
    });
};

const eventOnline = () => {
    if (toastOffline.value) {
        toast.remove(toastOffline.value.id); // Schließt den Offline-Toast
        toastOffline.value = null; // Setzt die Referenz zurück
    }
    // Zeige die erste Toast-Benachrichtigung an
    toastOnline.value = toast.add({
        color: 'green',
        icon: 'i-mdi-lan-connect',
        id: 'online', // Eindeutige ID, um doppelte Toasts zu vermeiden
        title: 'Network status',
        description: 'You are back online',
        closeButton: { icon: 'i-mdi-close' },
        // close: () => closeToastOnline(),
        timeout: 3456, // Kürzere Dauer für die erste Anzeige
    });

    // // Zeige eine zweite Toast-Benachrichtigung nach 7890 ms an
    // setTimeout(() => {
    //     toast.add({
    //         color: 'green',
    //         icon: 'i-mdi-local-area-network-connect',
    //         id: 'online-still', // Neue ID für die zweite Toast-Benachrichtigung
    //         title: 'Network status',
    //         description: 'You are still online',
    //         duration: 7890,
    //     });
    // }, 7890);
};

let jwtTimeout = null;

const { error: jwtFetchError, refresh: jwtFetchRefresh } = useAsyncData(
    'globals',
    () => $fetch('/api/v1/jwt'),
    {
        server: false,
        immediate: true,
        dedupe: 'cancel',
        transform: (e) => {
            clearTimeout(jwtTimeout);

            jwtTimeout = setTimeout(() => {
                jwtFetchRefresh();
            }, 56789);

            return e;
        },
    },
);

// watch(jwtFetchError, (error) => {
//     if (error) {
//         console.error('jwtFetchError', error)
//         useError(error, 'Can\'t load jwt token')
//     }
// })
// const fetchJWT = useFetch(
//     '/api/jwt',
//     {
//         method: 'GET',
//         headers: headers,
//         immediate: true,
//         server: false,
//         onRequest({ request, options }) {
//             clearTimeout(jwtTimeout)

//             jwtTimeout = setTimeout(() => {
//                 fetchJWT.refresh()
//             }, 66666)
//         },
//         onResponse({ request, response, options }) {

//             if (!response.ok) {
//                 this.onResponseError({ request, response, options })
//                 /*useError(response, 'Something is wrong with your JWT', {
//                     id: 'jwt',
//                     solution: 'You will be automatically logged out.',
//                     callback: () => { window.location.href = '/logout' },
//                 })*/
//                 this.abort()
//             }
//         },
//         onResponseError({ request, response, options }) {
//             ///window.location.href = '/logout'
//             // useError(response, 'Wrong or no userdata', {
//             //     id: 'jwt',
//             //     solution: 'You will be logged out.',
//             //     info: true,
//             //     callback: () => { window.location.href = '/logout' },
//             // })
//         }
//     }
// )

onMounted(async () => {
    /*setTimeout(() => {
            fetchJWT.refresh()
        }, 6666)*/

    nextTick(() => {
        useEvent('offline', eventOffline).setEventTarget(window);
        useEvent('online', eventOnline).setEventTarget(window);
    });

    window.addEventListener('offline', eventOffline);
    window.addEventListener('online', eventOnline);
    return;

    const observer = new MutationObserver(() => {
        clearTimeout(observerTimeout);
        const dialogElement = document.querySelector('dialog');
        const modalElement = document.querySelector('[role="dialog"]');

        observerTimeout = setTimeout(() => {
            if (dialogElement || modalElement) {
                document.getElementById('__nuxt').classList.add('blur-content');
                //console.log('Dialog detected');
                //observer.disconnect();
            } else {
                document
                    .getElementById('__nuxt')
                    .classList.remove('blur-content');
                //console.log('Dialog disappeared');
                //observer.disconnect();
            }
        }, 34.5);
    });

    // Starten Sie die Beobachtung des DOM auf Änderungen
    observer.observe(document.body, { childList: true, subtree: true });

    window.popStateDetected = false;
    window.addEventListener('popstate', () => {
        window.popStateDetected = true;
    });

    router.beforeEach((to, from, next) => {
        const IsItABackButton = window.popStateDetected;
        window.popStateDetected = false;
        if (IsItABackButton && from.meta.someLogica) {
            next(false);
            return '';
        }
        next();
    });
});

/*window.addEventListener('offline', (e) => {
            console.log('offline');
            notifications.value.push({
                id: 'offline',
                color: 'red',
                title: 'Network status',
                description: 'offline',
            })
        });

        window.addEventListener('online', (e) => {
            console.log('online');
        });*/
onUnmounted(async () => {
    clearTimeout(observerTimeout);
});
</script>
<style lang="scss">
/* as */
</style>
