<template>
    <!--<EIJNS_DialogNotification
        :active="active"
        @close="handleError()"
    >
        <! --<p v-html="mainStoreState.notifications[Object.keys(mainStoreState.notifications)[0]].message"></p>-- >
        <p class="whitespace-pre">{{ error?.data?.error || error.message }}</p>
    </EIJNS_DialogNotification>
    <div class="area blur-2xl">
        <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>-->
    <pre>{{ err }}</pre>
    <UModal v-model="err.show" :ui="{ width: 'max-w-6xl mx-12 2xl:mx-48'}">
        <UCard :ui="{ body: { base: '!p-6 overflow-y-auto max-h-[60vh]'}, header: { background: '!bg-red-600 dark:!bg-red-800' }}">
            <template #header>
                <div class="modal-header-wrap">
                    <h3 class="text-base font-semibold leading-6 text-neutral-900 dark:text-white flex flex-row items-center">
                        <UIcon class="w-8 h-8 mr-2" name="i-mdi-skull" />
                        Fehler {{ err.data.statusCode }} - ohh, ohhh
                    </h3>
                    <UButton variant="ghost" color="white" icon="i-mdi-close" class="modal-header-close" @click="err.show = false" />
                </div>
            </template>
            <!--<div class="min-h-[40vh]">
                <vue-json-pretty 
                    class="!text-[12px]"
                    itemHeight="20" 
                    :data="errorMessage" 
                />
            </div>-->
            <p class="truncate">{{ err.data.statusMessage }} - {{ err.data?.data?.desc }}</p>
            <pre class="whitespace-pre-wrap mt-4 text-xs bg-stone-400/20 p-4 rounded-md">{{ err.data }}</pre>
            <template #footer>
                <div class="flex space-x-5">
                    <div class="grow"></div>
                    <UButton 
                        @click="clearError"
                        color="primary" 
                        variant="solid" 
                        label="ok, zurück" 
                    />
                </div>
            </template>
        </UCard>
    </UModal>
</template>
<script setup>
    
    /* ROUTE LADEN */
    const router = useRouter();

    const props = defineProps({
        error: Object
    })

    // error modal stuff
    const err = ref({
        show: false,
        data: {}
    })

    watch(computed(() => props.error), (e) => {
        err.value.data = {...e}
    }, { deep: true, immediate: true })

    watch(() => err.value.data.statusCode, (code) => {
        //console.log('code', code, err)
        err.value.show = code ? true : false
    }, { immediate: true })

    watch(() => err.value.show, (val, oldVal) => {
        
        if (oldVal && !val) {
            clearError()
        }
    }, { immediate: true })

    /*if (process.client) {
        console.log('onErrorCapured')

        onErrorCaptured((err) => {
            console.log('onErrorCapured', err)
            error.value.show = true
            error.value.data = {...err}
            //error.value.data['cause']['url'] = window.location
        })
    }*/

    /*const active = {
        icon: 'mdi:skull',
        title: `${props.error.statusCode} - ${props.error.statusMessage || props.error.message || randomPhrase}`,
        error: props.error,
    }

    const hhandleError = () => window.closeDialog ? window.closeDialog() : clearError({ redirect: '/' })
    const handleError = () => {
        const error = {...props.error}

        if (error?.data?.back) {
            router.go(-1)
        }

        clearError()
    }*/

</script>
<style lang="scss">
/**/
.area{
    width: 100%;
    height:100vh;
    opacity: 50%;
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 100px;
    height: 100px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 66.6s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 200px;
    height: 200px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 200px;
    height: 200px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 300px;
    height: 300px;
    animation-delay: 0s;
    animation-duration: 40s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 200px;
    height: 200px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 250px;
    height: 250px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 150px;
    height: 150px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 30s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

</style>