<template>
    <h2 class="section-header">
        Urlaubsverwaltung
    </h2>
    <p>Passe hier Einstellungen für das Urlaubsmanagment an. Änderungen haben systembedingt keine Auswirkungen auf bereits verarbeitete Vorgänge bzw. Urlaubsanträge, eventuell musst du betreffende Urlaubsanträge in einen vorherigen Status zurücksetzen. Vorgesetzte und Vertreter sind im Bereich <NuxtLink to="/system/setup?tab=structure">Systemeinstellungen &raquo; Strukruren</NuxtLink> zu finden. Prinzipiell werden alle Vorgesetzte(n)/Chef(s) eines Bereiches benachrichtigt, die Absprache wer was wann bearbeitet, muss folglich untereinandner vereinbart werden.</p>
    <p class="mt-2">Es können eine oder mehrere übergeordnete Strukturelemente bzw. Verantwortliche als finale Instanz eingerichtet werden. Die festgelgten finalen Strukturen entscheiden quasi als letzte über die Urlaubsanträge der jeweils unterlagerten Strukturen. Alternativ, also wenn hier nichts ausgwählt ist, ist das oberste Strukturelement mit festgelegten Vorgsetzten/Chefs die finale Instanz. Ebenfalls kannst du hier die Nutzung dieses Moduls grundsätzlich einschränken, d.h. welche Benutzerrollen die Funktionen dieses Moduls, also die Urlaubsverwaltung an sich, nutzen dürfen (Achtung: dies muss in deinem Frontend entspr. umgesetzt sein).</p>

    <div class="col-span-2 flex-col gap-4">
        <UFormGroup class="mt-8" :label="(setupData.config.vacationeeze?.affiliations?.length > 1) ? 'Finale Strukturelement-Instanzen' : 'Finale Strukturelement-Instanz'">
            <USelectMenu 
                v-model="setupData.config.vacationeeze.affiliations" 
                value-attribute="id"
                optionAttribute="fullname"
                :options="affiliationsList"
                multiple
            >
                <template #label>
                    <template v-if="!setupData.config.vacationeeze?.affiliations?.[0]">
                        <span class="text-sm my-2 opacity-40">Noch keine Instanz festgelegt...</span>
                    </template>
                    <div v-else class="flex flex-wrap gap-2 my-1">
                        <template v-for="(id, index) of setupData.config.vacationeeze.affiliations">
                            <UTooltip :text="affiliationsList.find(e => e.id === id).fullname">
                                <UBadge :color="affiliationsList.find(e => e.id === id).color">
                                    <div class="flex flex-row">
                                        <span class="text-sm whitespace-nowrap">{{ affiliationsList.find(e => e.id === id).name }}</span>
                                        <UIcon @click.prevent.stop="setupData.config.vacationeeze.affiliations.splice(index, 1)" name="i-mdi-close-circle" class="inline-block w-5 h-5 align-middle ml-1 -mr-1 -mb-0.5 text-white/60 hover:text-white cursor-pointer" />
                                    </div>
                                </UBadge>
                            </UTooltip>
                        </template>
                    </div>
                </template>
                <template #option="{ option }">
                    <UBadge variant="solid" :color="option.color" class="p-1 -my-2">
                        <Icon class="inline-block w-6 h-6" :icon="option?.icon || 'none'" />
                    </UBadge>
                    {{ option.fullname }}
                </template>
            </USelectMenu>
        </UFormGroup>

        <UFormGroup class="mt-8" label="Benutzerrollen die die Urlaubsverwaltung nutzen dürfen">
            <USelectMenu 
                v-model="setupData.config.vacationeeze.roles" 
                :options="roles"
                multiple
            >
                <template #label>
                    <template v-if="!setupData.config.vacationeeze?.roles?.[0]">
                        <div class="text-sm my-2 opacity-40">Noch keine Rollen zugewiesen...</div>
                    </template>
                    <div v-else class="flex flex-wrap gap-2 my-1">
                        <template v-for="(name, index) of setupData.config.vacationeeze?.roles">
                            <UBadge color="white">
                                <div class="flex flex-row">
                                    <span class="text-sm whitespace-nowrap">{{ name }}</span>
                                    <UIcon @click.prevent.stop="setupData.config.vacationeeze?.roles?.splice(index, 1)" name="i-mdi-close-circle" class="inline-block w-5 h-5 align-middle ml-1 -mr-1 -mb-0.5 text-stone-500/70  hover:text-red-500 cursor-pointer" />
                                </div>
                            </UBadge>
                        </template>
                    </div>
                </template>
            </USelectMenu>
        </UFormGroup>
    </div>

    <h2 class="section-header">Stichtag für Urlaubsansprüche</h2>
    <p>Leg hier einen Stichtag fest, an dem die Urlaubsansprüche abgegolten sein müssen, bzw. an dem diese virtuell oder tatsächlich verfallen. Dieses Funktion versendet, falls zum festgelegten Stichtag noch Urlaubsansprüche bestehen bzw. bestehen würden, automatisch Benachrichtigungen an die Vorgesetzten und wenn gewünscht, an den jeweilige Mitarbeitenden.</p>

    <div class="mt-8 flex flex-col space-y-8">
        <div class="flex flex-row items-center mt-8">
            <UToggle 
                id="setupData.config.vacationeeze.expiryLeaveEntitlement.active"
                v-model="setupData.config.vacationeeze.expiryLeaveEntitlement.active"
                class="ml-2 mr-6"
                size="2xl" 
            />
            <label v-if="setupData.config.vacationeeze.expiryLeaveEntitlement.active" for="setupData.config.vacationeeze.expiryLeaveEntitlement.active" class="cursor-pointer">Stichtag wird verwendet</label>
            <label v-else for="setupData.config.vacationeeze.expiryLeaveEntitlement.active" class="cursor-pointer">Stichtag ist deaktiviert</label>
        </div>
        <div class="flex flex-row gap-4">
            <div class="grow">
                <UFormGroup label="Stichtag">
                    <UInput 
                        type="number"
                        :disabled="!setupData.config.vacationeeze.expiryLeaveEntitlement.active"
                        v-model="setupData.config.vacationeeze.expiryLeaveEntitlement.date.day" 
                        min="1"
                        max="31"
                    />
                </UFormGroup>
            </div>
            <!--<div v-if="invalidDeadline" class="w-full text-red-500 font-bold text-sm relative -top-2 pb-4">
                Die Tag-Monat-Angabe ist irgendwie nicht so ganz Korrekt!
            </div>-->
            <UFormGroup class="w-3/12" label="Monat">
                <USelectMenu 
                    :disabled="!setupData.config.vacationeeze.expiryLeaveEntitlement.active"
                    v-model="setupData.config.vacationeeze.expiryLeaveEntitlement.date.month" 
                    :options="monthNames" 
                    value-attribute="id"
                    optionAttribute="label" 
                >
                    <template #label>
                        {{ monthNames[setupData.config.vacationeeze.expiryLeaveEntitlement.date.month || 0].label }}
                    </template>
                </USelectMenu>
            </UFormGroup>
            <div class="w-4/12 pl-8">
                <UFormGroup label="Vorlaufzeit der Benachrichtigung">
                    <UInput 
                        type="number"
                        :disabled="!setupData.config.vacationeeze.expiryLeaveEntitlement.active"
                        v-model="setupData.config.vacationeeze.expiryLeaveEntitlement.leadTime.duration" 
                        min="0"
                        max="99999"
                    />
                </UFormGroup>
            </div>
            <div class="w-3/12">
                <UFormGroup label="Einheit (Vorlaufzeit)">
                    <USelectMenu 
                        :disabled="!setupData.config.vacationeeze.expiryLeaveEntitlement.active"
                        v-model="setupData.config.vacationeeze.expiryLeaveEntitlement.leadTime.unit" 
                        :options="units.duration" 
                        value-attribute="id"
                        :optionAttribute="(setupData.config.vacationeeze.expiryLeaveEntitlement.leadTime.duration > 1) ? 'namePlural' : 'name'" 
                >
                    <template #label>
                        {{ units.duration.find(e => e.id === setupData.config.vacationeeze.expiryLeaveEntitlement.leadTime.unit ?? 'day')?.[(setupData.config.vacationeeze.expiryLeaveEntitlement.leadTime.duration > 1) ? 'namePlural' : 'name'] }}
                    </template>
                </USelectMenu>
                </UFormGroup>
            </div>
        </div>
        <div class="flex flex-col space-y-8">
            <!--<div class="w-2/12"></div>
            <div class="w-3/12"></div>-->
            <div class="flex flex-row items-center" :class="!setupData.config.vacationeeze.expiryLeaveEntitlement.active ? 'line-through' : ''">
                <UToggle 
                    id="notificationExpiryLeaveEntitlement"
                    :disabled="!setupData.config.vacationeeze.expiryLeaveEntitlement.active || invalidDeadline"
                    v-model="setupData.config.vacationeeze.expiryLeaveEntitlement.notification"
                    class="ml-2 mr-6" 
                    size="2xl" 
                />
                <label v-if="setupData.config.vacationeeze.expiryLeaveEntitlement.notification" for="notificationExpiryLeaveEntitlement" class="cursor-pointer">Benachrichtignung an die Vorgesetzten und die Mitarbeiter:in versenden</label>
                <label v-else for="notificationExpiryLeaveEntitlement" class="cursor-pointer">nur die Vorgesetzten benachrichtigen und keine Benachrichtignung an die Mitarbeiter:in versenden</label>
            </div>
            <div class="flex flex-row items-center">
                <UToggle 
                    id="clearOnExpiryLeaveEntitlement"
                    :disabled="!setupData.config.vacationeeze.expiryLeaveEntitlement.active || invalidDeadline"
                    v-model="setupData.config.vacationeeze.expiryLeaveEntitlement.clearOn"
                    class="ml-2 mr-6" 
                    size="2xl" 
                />
                <label v-if="setupData.config.vacationeeze.expiryLeaveEntitlement.clearOn" for="clearOnExpiryLeaveEntitlement" class="cursor-pointer">nach erreichen der Frist bzw. ablauf der Deadline (Stichtag, Monat), die individuellen Urlaubsansprüche automatisch zurücksetzen</label>
                <label v-else for="clearOnExpiryLeaveEntitlement" class="cursor-pointer">die Urlaubsansprüche werden komplett manuell verwaltet</label>
            </div>
        </div>
    </div>

    <h2 class="section-header">Erinnerungen aka friendly-reminder</h2>
    <p>Es kommt ja vor, dass man etwas übersieht oder etwas vergisst, oder wenns dumm kommt, beides. Leg hier eine Zeitspanne fest, nach dessen Ablauf eine erneute E-Mail-Benachrichtigung als Erinnerung für die noch ausstehenden Aktionen an die Vorgesetzten versand wird. Meist handelt es sich dabei um die Bearbeitung eines Urlaubsantrages oder eines Widerrufs.</p>

    <div class="mt-12 flex flex-row gap-4">
        <div class="grow flex flex-row pr-4">
            <div class="w-full flex flex-row items-center">
                <UToggle 
                    id="reminderForLags"
                    v-model="setupData.config.vacationeeze.reminderForLags.active"
                    class="ml-2 mr-6" 
                    size="2xl" 
                />
                <label for="reminderForLags" class="cursor-pointer">erneute E-Mail-Benachrichtigung bei Verzögerungen</label>
            </div>
        </div>
        <div class="w-4/12 pl-8">
            <UFormGroup label="Erneute Benachrichtignung nach">
                <UInput 
                    type="number"
                    :disabled="!setupData.config.vacationeeze.reminderForLags.active"
                    v-model="setupData.config.vacationeeze.reminderForLags.duration" 
                    min="0"
                    max="99999"
                />
            </UFormGroup>
            <!--<div v-if="invalidDeadline" class="w-full text-red-500 font-bold text-sm relative -top-2 pb-4">
                Die Tag-Monat-Angabe ist irgendwie nicht so ganz Korrekt!
            </div>-->
        </div>
        <UFormGroup class="w-3/12" label="Einheit (Benachrichtignung)">
            <USelectMenu 
                :disabled="!setupData.config.vacationeeze.reminderForLags.active || setupData.config.vacationeeze.reminderForLags.duration == 0"
                v-model="setupData.config.vacationeeze.reminderForLags.unit" 
                :options="units.duration" 
                value-attribute="id"
                :optionAttribute="(setupData.config.vacationeeze.reminderForLags.duration > 1) ? 'namePlural' : 'name'" 
            >
                <template #label>
                    {{ units.duration.find(e => e.id === setupData.config.vacationeeze.reminderForLags.unit ?? 'day')?.[(setupData.config.vacationeeze.reminderForLags.duration > 1) ? 'namePlural' : 'name'] }}
                </template>
            </USelectMenu>
            <!--<USelectMenu 
                :disabled="!deadlineExpiryLeaveEntitlement"
                v-model="setupData.expiryLeaveEntitlement.date.month" 
                :options="monthNameList" 
                by="id" 
                optionAttribute="name" 
            >
            </USelectMenu>-->
        </UFormGroup>
        <!--<div class="w-4/12 pl-8">
            <UFormGroup label="Vorlaufzeit der Benachrichtigung">
                <UInput 
                    type="number"
                    :disabled="!deadlineExpiryLeaveEntitlement"
                    v-model="setupData.expiryLeaveEntitlement.leadTime.duration" 
                    min="0"
                    max="99999"
                />
            </UFormGroup>
        </div>
        <UFormGroup class="grow" label="von (Einheit)">
            <USelectMenu 
                :disabled="!deadlineExpiryLeaveEntitlement"
                v-model="setupData.expiryLeaveEntitlement.leadTime.unit" 
                :options="durationUnitList" 
                by="id" 
                :optionAttribute="(setupData.expiryLeaveEntitlement.leadTime.duration > 1) ? 'namePlural' : 'name'" 
            >
            </USelectMenu>
        </UFormGroup>-->
    </div>


    <h2 class="section-header">Basiseinheiten für Urlaubsanträge</h2>
    <p>Hier kannst du Zeiteinheiten für die Urlaubsanträge definieren. Grundsätzlich ist es so, ist kein konkretes Modell definiert, gilt immer der ganze Kalender- bzw. Werktag als Basiseinheit. Ebenso kannst du festlegen, wie die Berechnung der benötigten Urlaubstage mit dem Urlaubsanspruch der Mitarbeiter:in verrechnet wird.</p>

    <UButton 
        class="mt-8" 
        leading-icon="i-mdi-timer-plus"
        @click="openTimeModel({...templateTimeModel}, true)" 
        label="Basiseinheit hinzufügen" 
    />

    <UModal v-model="editTimeModelOpen" :ui="{ width: 'max-w-6xl mx-12 2xl:mx-48'}" :prevent-close="deleteTimeModelOpen">
        <UCard v-if="editTimeModel._edit" :ui="{ body: { base: 'max-w-6xl overflow-y-auto max-h-[calc(90vh-200px)]'}}">
            <template #header>
                <div class="modal-header-wrap">
                    <h3 class="text-base font-semibold leading-6 text-neutral-900 dark:text-white flex flex-row items-center">
                        <UIcon class="w-8 h-8 mr-2" name="i-mdi-timer-edit" />
                        Basiseinheit bearbeiten
                    </h3>
                    <UButton variant="ghost" icon="i-mdi-close" class="modal-header-close" @click="closeTimeModel(true)" />
                </div>
            </template>
            <div class="relative flex flex-col gap-4">
                <p class="mb-4">Vergebe eine aussagekräftige und beschreibende Bezeichnung. Bei der Dauer gilt, Dezimal- oder Ganzzahlen bezeichnen immer eine konkrete Stundenzahl, Angaben als Bruch stellen immer Teile des jeweiligen Kalender- bzw. Werktages dar.</p>
                <div class="flex gap-4">
                    <UFormGroup class="w-9/12" label="Bezeichnung">
                        <UInput 
                            v-model="editTimeModel.name"
                            autofocus
                        />
                    </UFormGroup>
                    <UFormGroup class="w-3/12" label="Dauer">
                        <UInput 
                            :disabled="editTimeModel.fullDay || editTimeModel.durationCustom"
                            v-model="editTimeModel.duration"
                        >
                            <template #trailing>{{ (editTimeModel.duration && editTimeModel.duration.match(/\d*\/\d*/g)) ? 'Tag' : 'h' }}</template>
                        </UInput>
                    </UFormGroup>
                </div>
                <UFormGroup label="Erläuterung (ggf. als JSON oder Javascipt-Objekt bei Mehrsprachigkeit)">
                    <div v-if="Array.isArray(decriptionDataType)" class="absolute -top-4 right-3.5 z-50">
                        <UTooltip text="Text" open-delay="500">
                            <UIcon name="i-mdi-text-box" class="w-6 h-6" />
                        </UTooltip>
                    </div>
                    <div v-else-if="decriptionDataType === 'json'" class="absolute -top-4 right-3.5 z-50">
                        <UTooltip text="JSON-Objekt" open-delay="500">
                            <UIcon name="i-mdi-code-json" class="w-6 h-6 text-green-400" />
                        </UTooltip>
                    </div>
                    <div v-else-if="decriptionDataType === 'js'" class="absolute -top-4 right-3.5 z-50">
                        <UTooltip text="JavaScript-Objekt" open-delay="500">
                            <UIcon name="i-mdi-language-javascript" class="w-6 h-6 text-yellow-400" />
                        </UTooltip>
                    </div>
                    <UTextarea 
                        autoresize
                        size="sm"
                        class="font-mono"
                        v-model="editTimeModel.description"
                        :ui="{ padding: { sm: 'px-4 py-3'} }"
                    />
                </UFormGroup>
                <div class="mt-8 flex space-x-4">
                    <div class="w-4/12 flex flex-row items-center">
                        <UToggle 
                            id="leaveAccount"
                            class="ml-2 mr-6" 
                            size="2xl"
                            v-model="editTimeModel.leaveAccount"
                        />
                        <label for="leaveAccount" class="cursor-pointer">mit dem Urlaubsanspruch verrechnen</label>
                    </div>
                    <div class="w-4/12 flex flex-row items-center">
                        <UToggle 
                            id="durationCustom"
                            class="ml-2 mr-6" 
                            size="2xl"
                            v-model="editTimeModel.durationCustom"
                        />
                        <label for="durationCustom" class="cursor-pointer">flexibel - für Überstunden bzw. Freizeitausgleich oder so</label>
                    </div>
                    <div class="w-4/12 flex flex-row items-center">
                        <UToggle 
                            id="fullDay"
                            class="ml-2 mr-6" 
                            size="2xl"
                            v-model="editTimeModel.fullDay"
                        />
                        <label for="fullDay" class="cursor-pointer">Ganztags - für einen ganzen Kalender- bzw. Werktag</label>
                    </div>


                    <v-switch
                        v-model="editTimeModel.leaveAccount"
                        hide-details
                        inset
                        class="w-33"
                        :color="(editTimeModel.leaveAccount) ? 'green-accent-4' : ''"
                        label="mit dem Urlaubsanspruch verrechnen"
                    ></v-switch>
                    <v-switch
                        :disabled="editTimeModel.fullDay"
                        v-model="editTimeModel.durationCustom"
                        hide-details
                        inset
                        class="w-33"
                        :color="(editTimeModel.durationCustom) ? 'green-accent-4' : ''"
                        label="flexibel - für Überstunden bzw. Freizeitausgleich oder so"
                    ></v-switch>
                    <v-switch
                        :disabled="editTimeModel.durationCustom"
                        v-model="editTimeModel.fullDay"
                        hide-details
                        inset
                        class="w-33"
                        :color="(editTimeModel.fullDay) ? 'green-accent-4' : ''"
                        label="Ganztags - für einen ganzen Kalender- bzw. Werktag"
                    ></v-switch>
                </div>
                <v-switch
                    v-model="editTimeModel.disabled"
                    hide-details
                    inset
                    :color="(editTimeModel.disabled) ? 'red-darken-4' : ''"
                    :label="(editTimeModel.disabled) ? 'Deaktiviert' : 'Aktiv'"
                ></v-switch>
            </div>
            <template #footer>
                <div class="flex space-x-5">
                    <UButton v-if="!editTimeModel._new" color="red" variant="ghost" trailing-icon="i-mdi-delete-outline" @click="deleteTimeModelOpen = true"  />
                    <div class="grow"></div>
                    <UButton @click="closeTimeModel(true)" variant="ghost">Abbrechen</UButton>
                    <UButton :disabled="editTimeModelRequired" @click="callbackTimeModel" color="primary" variant="solid">OK</UButton>
                </div>
            </template>
        </UCard>
    </UModal>

    <UModal v-model="deleteTimeModelOpen">
        <UCard :ui="{ body: { base: 'max-w-2xl !min-w-[666px]'}}">
            <template #header>
                <div class="modal-header-wrap">
                    <h3>
                        <UIcon class="w-8 h-8 mr-2" name="i-mdi-delete" />
                        Eintrag wirklich entfernen?
                    </h3>
                    <UButton variant="ghost" icon="i-mdi-close" class="modal-header-close" @click="deleteTimeModelOpen = false" />
                </div>
            </template>
            Hiermit wird der Eintrag <strong><em>{{ editTimeModel?.name }}</em></strong> entfernt.
            <template #footer>
                <div class="flex space-x-5">
                    <div class="grow"></div>
                    <UButton @click="deleteTimeModelOpen = false" variant="ghost">Nee, lieber nicht</UButton>
                    <UButton @click="callbackDeleteTimeModel" color="primary" variant="solid">Ja, löschs jetzt</UButton>
                </div>
            </template>
        </UCard>
    </UModal>

    <UTable 
        class="mt-8 timeModelTable"
        :rows="setupData.config.vacationeeze.timeModels" 
        :columns="timeModelColumns" 
        @select="selectRow"
    >
        <template #empty-state>
            <div class="text-center"><UBadge class="my-12 p-4 text-md">NO_DATA</UBadge></div>
        </template>

        <template #loading-state>
            <div class="text-center"><UBadge class="my-12 p-4 text-md animate-pulse">LOADING...</UBadge></div>
        </template>

        <template #name-data="{ row }">
            <strong>{{ row.name }}</strong>
            <template v-if="row.description">
                <br>
                <small>{{ row.description }}</small>
            </template>
        </template>

        <template #props-data="{ row }">
            <div class="flex gap-2 justify-end">
                <UBadge variant="subtle" v-if="row.fullDay" color="cyan">Ganztags</UBadge>

                <UTooltip text="Dauer" v-if="row.duration && !row.durationCustom && !row.fullDay">
                    <UBadge variant="subtle" color="orange">{{ row.duration }} {{ (row.duration.match(/\d*\/\d*/g)) ? 'Tag' : 'h' }}</UBadge>
                </UTooltip>

                <UBadge variant="subtle" v-if="row.leaveAccount" color="pink">Urlaubsanspruch</UBadge>
                <UBadge variant="subtle" v-if="row.durationCustom" color="green">Flexibel</UBadge>
            </div>
        </template>
    </UTable>
</template>
<script setup>

    import affiliations from '~/composables/user/affiliations.js';
    import _ from 'lodash'
    import { Icon } from '@iconify/vue';
    import units from '~/composables/data/units.js'
    import monthNames from '~/composables/data/monthNames.js'

    import dayjs from 'dayjs'
    import 'dayjs/locale/de'
    dayjs.locale('de')

    const { eijnsJWT, headers } = await useGlobals()
    const appStore = useAppStore()
    const { globals, roles } = storeToRefs(appStore)

    
    /* ROUTE LADEN */
    const route = useRoute();
    const router = useRouter();

    const props = defineProps({
        setupData: {
            type: Object,
        },
    })

    const emit = defineEmits(['commit'])

    /*const roles = ref([]);
    const fetchRoles = await useFetch(
        '/api/user/roles',
        { 
            onResponse({ request, response, options }) {

                if (!response.ok) {
                    this.onResponseError({ request, response, options })
                    return
                }

                roles.value = response._data.data;
            },
            onResponseError({ request, response, options }) {
                console.log('fetchRoles.onResponseError', response);
                appStore.error(null, {
                    ...response._data.error.value,
                    description: 'Fehler beim Laden der Benutzerrollen',
                    solution: 'Gib jemandem Bescheid, der sich damit auskennt',
                })
            }
        }
    )*/

    const reminderForLags = ref(props.setupData.config.vacationeeze.reminderForLags.duration > 0)
    const deadlineExpiryLeaveEntitlement = ref()
    const affiliationsList = ref();

    /* STICHTAG */
    const invalidDeadline = computed(
        () => {
            console.log(props.setupData.config.vacationeeze.expiryLeaveEntitlement.date.month % 2)

            if(props.setupData.config.vacationeeze.expiryLeaveEntitlement.date.month > 12
               || props.setupData.config.vacationeeze.expiryLeaveEntitlement.date.month < 1
               || props.setupData.config.vacationeeze.expiryLeaveEntitlement.date.day > 31
               || props.setupData.config.vacationeeze.expiryLeaveEntitlement.date.day < 1) {
                return true;
            }

            if(!(props.setupData.config.vacationeeze.expiryLeaveEntitlement.date.month % 2) 
               && props.setupData.config.vacationeeze.expiryLeaveEntitlement.date.day > 30) {
                return true;
            }

            if(props.setupData.config.vacationeeze.expiryLeaveEntitlement.date.month == 2 
               && props.setupData.config.vacationeeze.expiryLeaveEntitlement.date.day > 29) {
                return true;
            }

            return false;
        }
    )

    /* BASISEINHEITEN */
    const timeModelColumns = ref([
        {
            key: 'name',
            label: 'Bezeichnung',
        }, 
        {
            key: 'props',
            label: 'Eigenschaften',
        }
    ])

    const templateTimeModel = {
        title: '',
        duration: null,
        leaveAccount: false,
        durationCustom: false,
        fullDay: false,
        disabled: false,
    }

    const editTimeModel = ref()
    const editTimeModel_orig = ref()
    const editTimeModelOpen = ref()
    const deleteTimeModelOpen = ref()

    /* JSON ODER JAVASCRIPT OBJEKTE ERKENNEN */
    const decriptionDataType = computed(() => getDataType(editTimeModel.value?.description))
    const getDataType = (itemData) => {

        try{

            if (itemData?.trim()?.indexOf('[') !== 0
                && itemData?.trim()?.indexOf('{') !== 0
                && itemData?.trim()?.length > 0) {
                return itemData?.split(',').map(e => e.trim()).filter(e => e) || null
            }

            if (JSON.parse(itemData)) {
                return 'json'
            }
        } catch {
            try{
                if ((new Function('return ' + itemData))()) {
                    return 'js'
                }
            } catch {}
        }

        return false
    }

    /* MODAL STATUS BEOBACHTEN */
    watch(editTimeModelOpen, async (val, oldVal) => {

        if (!val) {
            closeTimeModel(true);
        }
    })
    // JAHRESTAG AUSWAEHLEN
    const selectRow = async (row) => {
        openTimeModel(row)
    }

    // ZU BEARBEITENDES OBJEKT ZURUECKSETZEN
    const resetEdit = async (cancel) => {

        if (editTimeModel.value?._new) {
            delete editTimeModel.value._new
        }

        props.setupData.config.vacationeeze.timeModels = props.setupData.config.vacationeeze.timeModels?.map(e => {
            if (e._edit) {
                // Erstelle eine Kopie des Objekts ohne das _edit-Feld
                const { _edit, ...rest } = cancel ?? e;
                return rest;
            }

            return e
        })//.filter(e => e.name !== '' || e.e.length > 0);
    }

    // EDITOR OEFFNEN
    const openTimeModel = (data, fromTemplate) => {
        resetEdit()
        data._edit = true

        if (fromTemplate) {
            editTimeModel.value = data
            editTimeModel.value._new = true
            props.setupData.config.vacationeeze.timeModels.push(editTimeModel.value)
            editTimeModel_orig.value = null
        } else {
            editTimeModel.value = data
            editTimeModel_orig.value = _.cloneDeep(data)
        }

        editTimeModelOpen.value = true
    }

    // EDITOR SCHLIESSEN
    const closeTimeModel = (cancel, noReset) => {
        editTimeModelOpen.value = false

        if (!noReset && !editTimeModel.value?._new) {
            resetEdit(cancel ? editTimeModel_orig.value : null)
        }

        if (cancel && (editTimeModel.value?._new)) {
            callbackDeleteTimeModel()
        } else {
            props.setupData.config.vacationeeze.timeModels.forEach(e => {

                if (e?._new) {
                    delete e._new
                }
            })
        }
    }

    // BEARBEITUNG UEBERNEHMEN
    const callbackTimeModel = () => {
        closeTimeModel()
    }

    // BEARBEITUNG LOESCHEN
    const callbackDeleteTimeModel = () => {
        props.setupData.config.vacationeeze.timeModels = props.setupData.config.vacationeeze.timeModels.filter(e => !e._edit);
        deleteTimeModelOpen.value = false
        closeTimeModel(null, true)
    }

    onBeforeMount(async () => {
    });

    onMounted(async () => {
        affiliationsList.value = await affiliations('*', globals.value.structure)
    });

</script>
<style scss>

.timeModelTable {
    & td:nth-child(1), 
    & th:nth-child(1) {
        width: auto;
    }

    & td:nth-child(2), 
    & th:nth-child(2) {
        text-align: right;
        width: 60%;
    }
}

</style>