export default defineNuxtPlugin((nuxtApp) => {

    nuxtApp.hook('app:mounted', async () => {
        // console.log('app:created')

        if ('serviceWorker' in navigator) {

            await navigator.serviceWorker
                .register('/serviceWorker.js')
                .then(async (registration) => {
                    console.log('Service Worker registered with scope:', registration);

                    /*try {

                                const { data, pending, error, refresh } = await useFetch('/api/public/subscribe', {
                                    method: 'POST',
                                    server: false,
                                    immediate: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: {},
                                    onRequest({ request, options }) {
                                        console.log('onRequest', request)
                                        // pageContent.value.isLoading = true
                                    },
                                    async onResponse({ request, response, options }) {
                                        console.log('response', response)
                                    },
                                    onRequestError({ request, options, error }) {
                                        console.error('onRequestError', error)
                                    },
                                    onResponseError({ request, response, options }) {
                                        console.error('onResponseError', error)
                                    }
                                })
                    } catch(err) {
                        console.error(err)
                        throw createError(err)
                    }*/

                    try {
                        // Überprüfe auf Aktualisierungen
                        registration.update();  // Service Worker explizit aktualisieren

                        registration.onupdatefound = () => {
                            const installingWorker = registration.installing;
                            installingWorker.onstatechange = () => {
                                
                                if (installingWorker.state === 'installed') {
                                    if (navigator.serviceWorker.controller) {
                                        // Neuer Service Worker installiert, Seite kann neu geladen werden
                                        console.log('Neuer Service Worker verfügbar.');
                                        // Zeige dem Benutzer eine Option zum Neuladen der Seite oder mach es automatisch
                                        // alert('Ein neues Update ist verfügbar. Seite neu laden?')
                                        // if (confirm('Ein neues Update ist verfügbar. Seite neu laden?')) {
                                            window.location.reload();
                                        // }
                                    } else {
                                        console.log('Service Worker installiert und aktiviert.');
                                    }
                                }
                            };
                        };
                        
                    } catch(err) {
                        console.error(err)
                        throw createError(err)
                    }
                })
                .catch(function(error) {
                    console.log('Service Worker registration failed:', error)
                })
        }
    })
})
