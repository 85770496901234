import CryptoJS from 'crypto-js';
import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
    state: () => ({
        title: 'EIJNS',
        version: 'v0.8.15',
        name: 'Hier sollte etwas anderen stehen',
        badge: null,
        icon: null,
        loading: true,
        progress: 100,
        lang: 'de',
        errors: {},
        notifications: {},
        mainScrollTop: 0,
        runtimeConfig: {},
        globals: {},
        roles: {},
        contextMenu: [],
        me: {
            credentials: {},
            preferences: {},
            favourites: {},
            messagesCount: null,
            avatar: null,
            email: null,
            phoneNumber: null,
            company: null,
            username: null,
            personal: {
                firstname: null,
                lastname: null,
            },
        }
    }),
    actions: {
        set(key, val) {
            // console.log('set', key, val)
            
            if (key.startsWith('me.')) {
                const subKey = key.split('.')
                this.me[subKey[1]] = val
            } else {
                this[key] = val
            }
        },
        removeContextMenu() {
            this.contextMenu = null
        },
        addNotification(id, data) {
            alert('addNotification deprecated!')

            //id = data?.statusCode || 666;

            if(!data.uuid) {
                //console.log('uuid')
                data.uuid = CryptoJS.MD5(JSON.stringify(data)).toString()
            }

            this.notifications[`${id || ''}${data.data.uuid}`] = {data, id}
        },
        deleteNotification(id) {
            alert('deleteNotification deprecated!')
            delete this.notifications[id]
        },
        error(id, data) {
            console.log('data', data)

            if(!data.data?.uuid) {
                data.uuid = CryptoJS.MD5(JSON.stringify(data)).toString()
            }

            this.errors[data?.id || `${id ?? ''}${data.uuid}`] = {data, id}
        },
        clearError(id) {
            console.log('app clearError', id)
            delete this.errors[id]
        },
    }
})