export default async function useError(err, msg, opt) {
    const appStore = useAppStore();
    // console.error('useError ... useError status', err.value.status)
    // console.error('useError ... useError statusCode', err.value.statusCode)
    // console.error('useError ... useError', {...err})
    // console.error('useError ... useError', {...err.value})

    // unref error
    if (err?.value?.statusCode) {
        err = {
            ...err?.value,
            status: err?.value?.statusCode,
            _data: err?.value?.data,
        };
    }

    if (!err?._data) {
        err = {
            ...err,
            status: err?.statusCode,
            _data: err?.data,
        };
    }

    // console.error('useError ... useError', err)

    switch (err?._data?.error?.stausCode?.value ?? err?.status) {
        case 400: {
            console.error('400 Bad Request:', err?._data?.message);
            appStore.error(null, {
                ...(opt?.id ? { id: opt.id } : {}),
                ...(opt?.callback ? { callback: opt.callback } : {}),
                error: err,
                ...err._data,
                description: msg,
                solution: opt?.solution || "Server can't process the request.",
            });
            break;
        }

        case 401: {
            console.error('401 Unauthorized:', msg);
            appStore.error(null, {
                ...(opt?.id ? { id: opt.id } : {}),
                ...(opt?.callback ? { callback: opt.callback } : {}),
                error: err,
                ...err._data,
                description: msg,
                solution:
                    opt?.solution ||
                    'Reload the page with [STRG]+[R] ... logout and re login.',
            });

            // setTimeout(() => {
            //     alert('L O G O U T' + msg)
            //     //window.location.href = '/logout'
            // }, 789)

            // const toast = useToast()
            // toast.add({
            //     title: 'Logout',
            //     description: 'You will be logout immediately.',
            //     icon: 'i-mdi-logout',
            //     timeout: 5000,
            // })
            break;
        }

        case 403: {
            console.error('403 Forbidden:', err?._data?.message);
            appStore.error(null, {
                ...(opt?.id ? { id: opt.id } : {}),
                ...(opt?.callback ? { callback: opt.callback } : {}),
                error: err,
                ...err._data,
                description: msg,
                solution:
                    opt?.solution ||
                    'Ask someone to grant you the corresponding permissions.',
            });
            break;
        }

        case 406: {
            console.error('406 Not Acceptable:', err?._data?.message);
            appStore.error(null, {
                ...(opt?.id ? { id: opt.id } : {}),
                ...(opt?.callback ? { callback: opt.callback } : {}),
                error: err,
                ...err._data,
                description: msg,
                solution: opt?.solution || 'Your query are incacceptable.',
            });
            break;
        }

        case 424: {
            console.error('424 Failed Dependency:', err?._data?.message);
            appStore.error(null, {
                ...(opt?.id ? { id: opt.id } : {}),
                ...(opt?.callback ? { callback: opt.callback } : {}),
                error: err,
                ...err._data,
                info: true,
                description: msg,
                solution: opt?.solution,
            });
            break;
        }

        case 429: {
            console.error('429 Too Many Requests:', err?._data?.message);
            appStore.error(null, {
                ...(opt?.id ? { id: opt.id } : {}),
                ...(opt?.callback ? { callback: opt.callback } : {}),
                error: err,
                ...err._data,
                description: msg,
                solution:
                    opt?.solution ||
                    'Wait a while before you perform this action again.',
            });
            break;
        }

        case 500: {
            console.error('500 Server Error:', err?._data?.message);
            appStore.error(null, {
                ...(opt?.id ? { id: opt.id } : {}),
                ...(opt?.callback ? { callback: opt.callback } : {}),
                error: err,
                ...err._data,
                description: msg,
                solution:
                    opt?.solution ||
                    'The server encountered an error. But we have no solution.',
            });
            break;
        }

        default: {
            console.error(`${err.status} Server Error:`, err?._data?.message);
            appStore.error(null, {
                ...(opt?.id ? { id: opt.id } : {}),
                ...(opt?.callback ? { callback: opt.callback } : {}),
                error: err,
                ...err._data,
                description: msg,
                solution:
                    opt?.sol ||
                    'The server encountered an error. But we have no solution.',
            });
        }
    }
}
