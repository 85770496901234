export default async function useGlobals() {
    //const runtimeConfig = useRuntimeConfig();
    //const appStore = useAppStore()
    //console.log('useGlobals appStore', appStore)
    const pagination = [3, 7, 21, 42, 73, 234, 404, 666]
    let eijnsJWT
    let JWTdata
    let headers

    try {
        let { eijnsJWT, JWTdata, headers } = await useAuth()
        // const runtimeConfig = useRuntimeConfig()
        // const appStore = useAppStore()
        // const { errors } = storeToRefs(appStore)

        // const fetchGlobal = await useFetch(
        //     '/api/globals',
        //     { 
        //         method: 'GET',
        //         headers: headers,
        //         immediate: true,
        //         server: false,
        //         onResponse({ request, response, options }) {
        //             //console.log('response._data', response._data)

        //             // if (!response.ok) {
        //             //     useError(response, 'Something is wrong while load globals')
        //             //     return
        //             // }

        //             // appStore.set('globals', response._data.data.globals)
        //             // appStore.set('roles', response._data.data.roles)
        //             // appStore.set('me.favourites', response._data.data.me.favourites)
        //             // appStore.set('me.avatar', response._data.data.me.avatar)
        //             // appStore.set('me.email', response._data.data.me.email)
        //             // appStore.set('me.username', response._data.data.me.username)
        //             // appStore.set('me.personal', response._data.data.me.personal)
        //             // appStore.set('me.phone', response._data.data.me.phone)
        //             // appStore.set('me.company', response._data.data.me.company)
        //             // appStore.set('runtimeConfig', {
        //             //     app: runtimeConfig.app,
        //             //     package: runtimeConfig.public.package
        //             // })
        //         },
        //         onResponseError({ request, response, options }) {
        //             useError(response, 'Can\'t load globals')
        //         },
        //     }
        // )
        // const fetchGlobal = await useFetch(
        //     '/api/globals',
        //     { 
        //         method: 'GET',
        //         headers: headers,
        //         immediate: true,
        //         server: false,
        //         onResponse({ request, response, options }) {
        //             //console.log('response._data', response._data)

        //             if (!response.ok) {
        //                 useError(response, 'Something is wrong while load globals')
        //                 return
        //             }

        //             appStore.set('globals', response._data.globals)
        //             appStore.set('roles', response._data.roles)
        //             appStore.set('me.favourites', response._data.me.favourites)
        //             appStore.set('me.avatar', response._data.me.avatar)
        //             appStore.set('me.email', response._data.me.email)
        //             appStore.set('me.username', response._data.me.username)
        //             appStore.set('me.personal', response._data.me.personal)
        //             appStore.set('me.phone', response._data.me.phone)
        //             appStore.set('me.company', response._data.me.company)
        //             appStore.set('runtimeConfig', {
        //                 app: runtimeConfig.app,
        //                 package: runtimeConfig.public.package
        //             })
        //         },
        //         onResponseError({ request, response, options }) {
        //             useError(response, 'Can\'t load globals')
        //         },
        //     }
        // )

        const fetchGlobals = async () => {
            /*console.log('fetchGlobals')

            try {
                const globalData = await $fetch(
                    '/api/globals', 
                    {
                        method: 'GET',
                        headers: headers
                    }
                )

                console.log('globalData', globalData)
                appStore.set('globals', globalData)
                appStore.set('runtimeConfig', {
                    app: runtimeConfig.app,
                    package: runtimeConfig.public.package
                })
                //return globalData
            } catch(err) {
                console.error('globalData err', err)
                appStore.error(null, {
                    ...err,
                    description: 'Fehler beim Laden der globalen daten',
                    solution: 'Gib jemandem Bescheid, der sich damit auskennt',
                })
            }

            try {
                const meData = await $fetch(
                    '/api/me', 
                    {
                        method: 'GET',
                        headers: headers
                    }
                )

                console.log('meData', meData)
                appStore.set('me.favourites', meData.favourites)
                appStore.set('me.avatar', meData.avatar)
                appStore.set('me.email', meData.email)
                appStore.set('me.username', meData.username)
                appStore.set('me.personal', meData.personal)
                appStore.set('me.phone', meData.phone)
                appStore.set('me.company', meData.company)
                //return globalData
            } catch(err) {
                console.error('globalData err', err)
                appStore.error(null, {
                    ...err,
                    description: 'Fehler beim Laden deiner Benutzerdaten',
                    solution: 'Gib jemandem Bescheid, der sich damit auskennt',
                })
            }*/

            return true
        }

        //if (!appStore.globals) {
            //await fetchGlobals()
        //}

    } catch(err) {
        if (process.client) {
            console.error('useGlobals auth error', err)
        }
    }

    return {
        // fetchGlobals: () => {},
        eijnsJWT,
        JWTdata,
        headers,
        pagination, 
        //appStore,
    }
}