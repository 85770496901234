import dayjs from 'dayjs'
import 'dayjs/locale/de'
dayjs.locale('de')

const monthNames = Array.from({ length: 12 }, (_, i) => {
    return { 
        id: i,
        label: dayjs(0).add(i, 'month').format('MMMM'),
    }
})

export default monthNames