const __filename = '/modules/vacationeeze/plugins/index.js';
import chalk from 'chalk';
import dayjs from 'dayjs'
import 'dayjs/locale/de.js'
dayjs.locale('de')
import units from '~/composables/data/units.js'
import monthNames from '~/composables/data/monthNames.js'
import { defineNuxtPlugin } from '#app'

import agreements from '../agreements.json'
import credentials from '../credentials.json'
import navigation from '../navigation.json'
import notifications from '../notifications.json'

import setupPage from '../pages/setup/tab.default.vue'

export default defineNuxtPlugin(async (nuxtApp) => {

    if (!nuxtApp.$eijnsApp) {
        nuxtApp.provide('eijnsApp', {})
    }

    nuxtApp.$eijnsApp.agreements = {
        ...nuxtApp?.$eijnsApp?.agreements,
        ...agreements,
    }

    nuxtApp.$eijnsApp.credentials = {
        ...nuxtApp?.$eijnsApp?.credentials,
        ...credentials,
    }

    nuxtApp.$eijnsApp.navigation = {
        ...nuxtApp?.$eijnsApp?.navigation,
        vacationeeze: navigation,
    }

    nuxtApp.$eijnsApp.notifications = {
        ...nuxtApp?.$eijnsApp?.notifications,
        // ...notifications,
    }

    if (!nuxtApp.$eijnsApp.setupPages) {
        nuxtApp.$eijnsApp.setupPages = {}
    }

    nuxtApp.$eijnsApp.setupPages.vacationeeze = setupPage

    if (!nuxtApp.$eijnsApp.setupSave) {
        nuxtApp.$eijnsApp.setupSave = {}
    }

    nuxtApp.$eijnsApp.setupSave.vacationeeze = async (event, body) => {
        // console.log(chalk.yellow(`${dayjs().format('YYYY-MM-DD HH:mm:ss.SSS')} ... ${event?.description})`))
        // console.log(chalk.blue(`${__filename} ... default()`))
        // console.log(body)
        // let dataToSave = {}

        // if (typeof body['config.vacationeeze.affiliations'] !== 'undefined') {

        //     if (Array.isArray(body['config.vacationeeze.affiliations'])) {
        //         dataToSave['config.vacationeeze.affiliations'] = body['config.vacationeeze.affiliations']
        //     }
        // }

        // if (typeof body['config.vacationeeze.roles'] !== 'undefined') {

        //     if (Array.isArray(body['config.vacationeeze.roles'])) {
        //         dataToSave['config.vacationeeze.roles'] = body['config.vacationeeze.roles']
        //     }
        // }

        // if (typeof body['config.vacationeeze.expiryLeaveEntitlement.active'] !== 'undefined') {

        //     if (Array.isArray(body['config.vacationeeze.expiryLeaveEntitlement.active'])) {
        //         dataToSave['config.vacationeeze.expiryLeaveEntitlement.active'] = body['config.vacationeeze.expiryLeaveEntitlement.active'] ? true : false
        //     }
        // }

        // if (typeof body['config.vacationeeze.expiryLeaveEntitlement.date.day'] !== 'undefined') {

        //     if (Array.isArray(body['config.vacationeeze.expiryLeaveEntitlement.date.day'])) {
        //         dataToSave['config.vacationeeze.expiryLeaveEntitlement.date.day'] = parseInt(body['config.vacationeeze.expiryLeaveEntitlement.date.day'])
        //     }
        // }

        // if (typeof body['config.vacationeeze.expiryLeaveEntitlement.date.month'] !== 'undefined') {

        //     if (Array.isArray(body['config.vacationeeze.expiryLeaveEntitlement.date.month'])) {
        //         dataToSave['config.vacationeeze.expiryLeaveEntitlement.date.month'] = parseInt(body['config.vacationeeze.expiryLeaveEntitlement.date.month'])
        //     }
        // }

        // if (typeof body['config.vacationeeze.expiryLeaveEntitlement.leadTime.duration'] !== 'undefined') {

        //     if (Array.isArray(body['config.vacationeeze.expiryLeaveEntitlement.leadTime.duration'])) {
        //         dataToSave['config.vacationeeze.expiryLeaveEntitlement.leadTime.duration'] = parseInt(body['config.vacationeeze.expiryLeaveEntitlement.leadTime.duration'])
        //     }
        // }

        // return dataToSave
    }

    /*const contentQuery = await useAsyncData('navigation', () => fetchContentNavigation())
    const releaseNext = await useAsyncData('page-data', () => queryContent('/vacationeeze/releases/next').findOne())

    console.log('releaseNext', releaseNext)

    nuxtApp.$eijnsApp.content = [
        ...(nuxtApp?.$eijnsApp?.content ? nuxtApp.$eijnsApp.content : []),
        contentQuery,
    ]*/

    console.log(chalk.yellow(`${dayjs().format('YYYY-MM-DD HH:mm:ss.SSS')} ...`))
    console.log(chalk.blue(`${__filename} ... defineNuxtPlugin()`))
    console.log('vacationeeze-module plugin injected')
    //console.log(nuxtApp.$eijnsApp)
})