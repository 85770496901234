const __filename = '/composables/useAuth.js'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
dayjs.locale('de')

export default function useAuth() {

    if (process.client) {

        try {
            const router = useRouter()
            let error = false
            let JWTdata = null
            let eijnsJWT = useCookie('eijns.jwt')?.value
            let headers = { 'x-requestor': 'e1jns' }

            if (typeof eijnsJWT === 'undefined') {
                error = true
            } else {
                JWTdata = ref(JSON.parse(atob(eijnsJWT?.split('.')[1])))
                const cookieRecord = useRequestHeaders(["cookie"])
                headers.authorization = `Bearer ${ eijnsJWT }`
            }

            /*if (error || !JWTdata || dayjs().isAfter(dayjs.unix(JWTdata?.value?.exp))) {
                console.error(`${__filename} ... useAuth() error or no JWTdata on ${window.location.pathname}`)

                if (!['', '/', '/login', '/logout'].includes(window.location.pathname)
                    && !window.location.pathname.startsWith('/help')
                    && !window.location.pathname.startsWith('/public')
                    && !window.location.pathname.startsWith('/verify')
                    && !window.location.pathname.startsWith('/confirm')
                    // && !window.location.pathname.startsWith('/password')
                    // && !window.location.pathname.startsWith('/register')
                    && !window.location.pathname.startsWith('/support')
                    && !window.location.pathname.startsWith('/metrics')
                    && !window.location.pathname.startsWith('/health')
                    && !window.location.pathname.startsWith('/api')
                ) {
                    window.location.href = '/logout'
                }
            }*/

            return {
                eijnsJWT: eijnsJWT,
                JWTdata: JWTdata || {},
                headers: headers || {},
            }
        } catch(err) {
            console.error(`${__filename} ... useAuth() catch`, err)
            return {
                eijnsJWT: 'none',
                JWTdata: {},
                headers: {},
            }
        }
    }
}