<template>

    <h2 class="section-header">
        Buchungskontrolle
    </h2>

    <p>Hier kannste ein paar Parameter der Kontrolleinheit, die für die eigentliche Prüfung der Buchungsdifferenzen zwischen Anwesenheitszeiten der Zeiterfassung und den AP+ Buchungen der Kostenstellen- oder Projektzeiten zuständig ist, anpassen. Die Änderungen werden höchstwahrscheinlich erst im nächsten Prüflauf Wirkung zeigen. Du kannst aber einen außerordentlichen Prüflauf anstoßen, <strong>beachte aber, dass ein außerordentlicher Prüflauf, den zusätzlichen Versand von Benachrichtigungen an die Nutzer veranlassen wird.</strong></p>

    <div v-if="setupData.config.timings" class="flex flex-col gap-4">
        <!-- <h2 class="sub-section-header">Benachrichtigungen zu Arbeitszeitkorrekturen</h2> -->
        <h2 class="section-header flex flex-row items-end">
            <span>Benachrichtigungen zu Arbeitszeitkorrekturen</span>
            <!-- <div class="grow"></div>
            <div class="text-base font-normal flex flex-row items-center flex-row-reverse mt-8">
                <UTooltip :text="`Prüfung und Benachrichtigungsversand: ${setupData.config.modules.rantomat.enablePublicCheck ? 'AKTIV' : 'DEAKTIVIERT'}`">
                    <UToggle 
                        v-if="setupData.config.modules.rantomat"
                        id="enablePublicCheck"
                        size="2xl"
                        v-model="setupData.config.modules.rantomat.enablePublicCheck"
                        color="lime"
                        on-icon="i-mdi-check"
                        off-icon="i-mdi-close"
                        :ui="{ inactive: 'bg-red-800 dark:bg-red-800' }"
                    />
                </UTooltip>
            </div> -->
        </h2>

        <UFormGroup label="Wer soll die Nachrichten für die Arbeitszeitkorrekturen erhalten?" >
            <USelectMenu 
                v-model="setupData.config.modules.rantomat.worktimeSupport" 
                value-attribute="_id"
                optionAttribute="fullname"
                :options="userList"
                ref="editSupervisorSelectMenu"
                multiple
                searchable
                searchable-placeholder="Such nach jemandem..."
            >
                <template #label>
                    <template v-if="setupData.config.modules.rantomat?.worktimeSupport?.length === 0">
                        <div class="text-sm my-3">Es ist noch niemand ausgewählt...</div>
                    </template>
                    <div v-else class="flex flex-wrap gap-2">
                        <template v-for="(user, index) of setupData.config.modules.rantomat.worktimeSupport?.map(e => userList.find(u => e === u._id)).filter(e => e)">
                            <UBadge 
                                class="overflow-hidden badge-content"
                                color="gray"
                                :ui="{ rounded: 'rounded-full' }"
                            >
                                <UTooltip :text="[user?.personal?.title, user?.personal?.firstname, user?.personal?.lastname].filter(e=>e).join(' ')">
                                    <UIcon v-if="!user?.avatar" name="i-mdi-account-circle" class="w-11 h-11 -my-1 mr-1 -ml-1.5" />
                                    <UAvatar 
                                        v-else
                                        :src="user?.avatar"
                                        size="md"
                                        :ui="{ rounded: 'rounded-full'}"
                                        class="-my-0.5 mr-1 -ml-1.5"
                                    />
                                </UTooltip>
                                <span>
                                    <UTooltip :text="user?.email || 'Noch keine Bestätigte E-Mail-Adresse vorhanden...'">
                                        {{ user?.username }}
                                    </UTooltip>
                                </span>
                                <UIcon @click.prevent.stop="setupData.config.modules.rantomat.worktimeSupport?.splice(index, 1)" name="i-mdi-close-circle" class="badge-icon-close" />
                            </UBadge>
                        </template>
                    </div>
                </template>

                <template #option="{option}">
                    <div @click="close" class="flex flex-row items-center gap-2">
                        <div class="bg-gray-500 w-10 h-10 rounded-md text-white overflow-hidden group relative">
                            <UIcon v-if="!option.avatar" name="i-mdi-account-circle" class="w-10 h-10" />
                            <UAvatar 
                                v-else
                                :src="option.avatar"
                                size="md"
                                :ui="{ rounded: 'rounded-none'}"
                            />
                        </div>
                        <div class="whitespace-normal flex flex-col">   
                            <div><strong>{{ option.username }}</strong> <sup><em>aka</em></sup> {{ [option.personal.title, option.personal.firstname, option.personal.lastname].filter(e => e).join(' ') }}</div>
                            <small>{{ option.email }}</small>
                        </div>
                    </div>
                </template>
            </USelectMenu>
        </UFormGroup>

        <div class="flex flex-row gap-4 mt-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Prüfung und Benachrichtigungsversand:</strong> 
                    Diese Funktion kann über den Public-Trigger-Endpunkt ausgeführt werden.
                    Im Header müssen via <stong>.env</stong> festgelegte Parameter (AUTH_API_KEY, 
AUTH_API_SECRET) übermittelt werden. Auth-API-Key ist die ObjectID des Company-Datensatzes, und der zugehörige Wert Auth-API-Value ist ein zufälliger Token. Dies erstellt im Prinzip die Rantomat-Datensätze vom Typ "result".</p>
                <p class="mt-4">
                    <code class="bg-indigo-600 text-white font-bold text-sm p-1 pb-0.5 rounded-md">https://example.com/api/rantomat/[employeeId]/check.public</code> ... Manuell - explizit, benutzerbezogen 
                </p>
                <p class="mt-4">
                    <code class="bg-indigo-600 text-white font-bold text-sm p-1 pb-0.5 rounded-md">https://example.com/api/rantomat/all/check.public</code> ... Automatisiert - zyklish<br>
                    <small><strong>INFO:</strong> je Aufruf wird ein Benutzer geprüft und ggf. benachrichtigt, es wird die <code class="bg-indigo-600 text-white font-bold p-1 pb-0.5 rounded-md">employeeId</code> des bearbeiteten Benutzers zurückgegeben</small>
                </p>
                <p class="mt-4">
                    <strong>ACHTUNG:</strong> Beschränke den Zeitraum für den Aufruf via CRON etc. auf etwas sinnvolles, andernfalls wird immer kurz nach Mitternacht, wenn bspw. neue Datensätze hinzugekommen sind oder sich Änderungen ergeben haben, benachrichtigt.
                </p>
            </div>
            <div class="w-1/3">
                <div class="text-base font-normal flex flex-row items-center flex-row-reverse">
                    <UTooltip :text="`Prüfung und Benachrichtigungsversand: ${setupData.config.modules.rantomat.enablePublicCheck ? 'AKTIV' : 'DEAKTIVIERT'}`">
                        <UToggle 
                            v-if="setupData.config.modules.rantomat"
                            id="enablePublicCheck"
                            size="2xl"
                            v-model="setupData.config.modules.rantomat.enablePublicCheck"
                            ccolor="lime"
                            uon-icon="i-mdi-check"
                            uoff-icon="i-mdi-close"
                            u:ui="{ inactive: 'bg-red-800 dark:bg-red-800' }"
                        />
                    </UTooltip>
                </div>
            </div>
        </div>

        <div class="flex flex-row gap-4 mt-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Analyse erzwingen:</strong> 
                    Überschreibt die Standardfunktion, dass jede Prüfung je Benutzer nur einmal Täglich ausgeführt wird. Falls dies in Kombination mit der automatisierten Prüfung genutz wird, ist diese quasi deaktiviert, so muss die Prüfung für jeden Benutzer manuell und explizit ausgeführt werden.
                </p>
            </div>
            <div class="w-1/3">
                <div class="text-base font-normal flex flex-row items-center flex-row-reverse">
                    <UTooltip :text="`Erneute Analyse erzwingen: ${setupData.config.modules.rantomat.forceReparseData ? 'AKTIV' : 'DEAKTIVIERT'}`">
                        <UToggle 
                            v-if="setupData.config.modules.rantomat"
                            id="forceReparseData"
                            size="2xl"
                            v-model="setupData.config.modules.rantomat.forceReparseData"
                            icolor="lime"
                            ion-icon="i-mdi-check"
                            ioff-icon="i-mdi-close"
                            i:ui="{ inactive: 'bg-red-800 dark:bg-red-800' }"
                        />
                    </UTooltip>
                </div>
            </div>
        </div>

        <div class="flex flex-row gap-4 mt-4 p-4 hover:bg-indigo-400/10" v-if="setupData.config.modules.rantomat.forceReparseData">
            <div class="w-2/3">
                <p><strong class="uppercase">Benachrichtigung erzwingen:</strong> 
                    Überschreibt die Standardfunktion, dass jede Benachrichtigung je Prüfung mit erkannten Fehlern nur einmal Täglich versandt wird. Folglich wird der Benutzer mehrfach benachrichtigt. Funktioniert nur in Kombination mit <strong class="uppercase">Analyse erzwingen</strong>.
                </p>
            </div>
            <div class="w-1/3">
                <div class="text-base font-normal flex flex-row items-center flex-row-reverse">
                    <UTooltip :text="`Erneute Benachrichtigung erzwingen: ${setupData.config.modules.rantomat.forceResendNotification ? 'AKTIV' : 'DEAKTIVIERT'}`">
                        <UToggle 
                            v-if="setupData.config.modules.rantomat"
                            id="forceResendNotification"
                            size="2xl"
                            v-model="setupData.config.modules.rantomat.forceResendNotification"
                            color="lime"
                            on-icon="i-mdi-check"
                            off-icon="i-mdi-close"
                            :ui="{ inactive: 'bg-red-800 dark:bg-red-800' }"
                        />
                    </UTooltip>
                </div>
            </div>
        </div>
    </div>

    <div v-if="setupData.config.timings" class="flex flex-col gap-4">
        <h2 class="sub-section-header">Versand von Hinweisen</h2>
        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Zeiträume:</strong> Definiert die Wochentage und Zeitfenster, in denen Benachrichtigungen über fehlerhafte Buchungen versendet werden sollen. Die Stundenangaben basieren auf der lokalen Zeitzone und werden als umgerechnete UTC-Werte gespeichert.</p>
            </div>
            <div class="w-1/3 flex flex-row gap-4">
                <UFormGroup class="flex-1 w-1/3" :label="sendHours.length < 2 ? 'Stunde' : `Stunden (${sendHours.length})`">
                    <USelectMenu 
                        v-model="sendHours" 
                        vvalue-attribute="id"
                        opptionAttribute="name"
                        :options="Array.from({ length: 24 }, (_, idx) => `${++idx}`).map(e => e - 1)"
                        icon="i-mdi-clock"
                        multiple
                    >
                        <template #label>
                            <div class="truncate">{{ sendHours.sort().join(', ') || '&nbsp;' }}</div>
                        </template>
                        <template #option="{ option }">
                            {{ option }}:00 - {{ option }}:59
                        </template>
                    </USelectMenu>
                </UFormGroup>
                <UFormGroup class="flex-none w-2/3" :label="setupData.config.modules.rantomat.sendWeekdays.length < 2 ? 'Wochentag' : `Wochentage (${setupData.config.modules.rantomat.sendWeekdays.length})`">
                    <USelectMenu 
                        v-model="setupData.config.modules.rantomat.sendWeekdays" 
                        value-attribute="id"
                        optionAttribute="name"
                        :options="Array.from({ length: 7 }, (_, idx) => ({ id: idx, name: dayjs().isoWeekday(idx).format('dddd') }))"
                        icon="i-mdi-calendar-week"
                        multiple
                    >
                        <template #label>
                            <div class="truncate">{{ setupData.config.modules.rantomat.sendWeekdays.sort().map(e => dayjs().isoWeekday(e).format('ddd')).join(', ') || '&nbsp;' }}</div>
                        </template>
                    </USelectMenu>
                </UFormGroup>
            </div>
        </div>
    </div>

    <div v-if="setupData.config.timings" class="flex flex-col gap-4">
        <h2 class="sub-section-header">Pausen- und Arbeitszeiten</h2>

        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Mindestarbeitszeit für die erste Pausenregelung:</strong> Die Mindestzeit, die gearbeitet werden muss, bevor eine automatische Pausenkorrektur wirksam wird. Sie stellt eine Voraussetzung dar, um die Anpassung der Pausenzeiten zu aktivieren. Falls die Arbeitszeit unter diesem Wert liegt, bleibt die Pausenzeit unverändert.</p>
            </div>
            <div class="w-1/3">
                <UFormGroup label="Mindestarbeitszeit #1">
                    <UInput 
                        type="number"
                        min="0"
                        max="999999"
                        v-model="setupData.config.modules.rantomat.breakPerWorkingTime"
                        placeholder="Gib eine Zeit in Sek. ein..."
                        :ui="{ trailing: { padding: { 'xl': 'pe-24' } } }"
                    >
                        <template #trailing>Sekunden</template>
                    </UInput>
                </UFormGroup>
                <small class="block pt-2 opacity-60 font-bold">
                    Das sind ca. 
                    <template v-if="setupData.config.modules.rantomat.breakPerWorkingTime > 3600">
                        {{ dayjs.duration(setupData.config.modules.rantomat.breakPerWorkingTime, 'seconds').hours() }} h
                        {{ dayjs.duration(setupData.config.modules.rantomat.breakPerWorkingTime, 'seconds').minutes() }} m
                    </template>
                    <template v-else>
                        {{ parseInt(dayjs.duration(setupData.config.modules.rantomat.breakPerWorkingTime, 'seconds').asMinutes()) }} min
                    </template>
                </small>
            </div>
        </div>

        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Die erste Pausenzeit:</strong> Die vorgeschriebene Pausendauer, die eingehalten werden muss, sobald die Mindestarbeitszeit erreicht ist, um gesetzliche Anforderungen zu erfüllen. Wenn die tatsächliche Arbeitszeit die Mindestarbeitszeit überschreitet, aber eine Pausenzeitkorrektur nicht durchgeführt wurde, wird die Pausendauer auf diesen Wert aufgerundet. Das bedeutet, die Pause wird mindestens auf diese Dauer gesetzt, auch wenn tatsächlich weniger Pause genommen wurde. Diese Aufrundung erfolgt nur, wenn die Mindestarbeitszeit erfüllt ist.</p>
            </div>
            <div class="w-1/3">
                <UFormGroup label="Pausenzeitkorrektur #1">
                    <UInput 
                        type="number"
                        min="0"
                        max="999999"
                        v-model="setupData.config.modules.rantomat.breakDuration"
                        placeholder="Gib eine Zeit in Sek. ein..."
                        :ui="{ trailing: { padding: { 'xl': 'pe-24' } } }"
                    >
                        <template #trailing>Sekunden</template>
                    </UInput>
                </UFormGroup>
                <small class="block pt-2 opacity-60 font-bold">
                    Das sind ca. 
                    <template v-if="setupData.config.modules.rantomat.breakDuration > 3600">
                        {{ dayjs.duration(setupData.config.modules.rantomat.breakDuration, 'seconds').hours() }} h
                        {{ dayjs.duration(setupData.config.modules.rantomat.breakDuration, 'seconds').minutes() }} m
                    </template>
                    <template v-else>
                        {{ parseInt(dayjs.duration(setupData.config.modules.rantomat.breakDuration, 'seconds').asMinutes()) }} min
                    </template>
                </small>
            </div>
        </div>
    </div>

    <div v-if="setupData.config.timings" class="flex flex-col gap-4">
        <h2 class="sub-section-header">Buchungszeitdifferenzen</h2>
        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Differenz zwischen Arbeits- und Buchungszeiten:</strong> Der Schwellenwert, der bei der Fehlerbewertung verwendet wird, wenn die korrigierte Arbeitszeit die tatsächlichen AP+ Buchungen unter- oder überschreitet. Dieser Wert wird nur bei negativen Abweichungen berücksichtigt.</p>
            </div>
            <div class="w-1/3">
                <UFormGroup label="Diff. zw. Arbeits- und Buchungszeit">
                    <UInput 
                        type="number"
                        min="0"
                        max="999999"
                        v-model="setupData.config.modules.rantomat.thresholdWorktimeRecordsDiff"
                        placeholder="Gib eine Zeit in Sek. ein..."
                        :ui="{ trailing: { padding: { 'xl': 'pe-24' } }, leading: { padding: { 'xl': 'ps-5' } } }"
                    >
                        <!-- <template #leading>-</template> -->
                        <template #trailing>Sekunden</template>
                    </UInput>
                </UFormGroup>
                <small class="block pt-2 opacity-60 font-bold">
                    Das sind ca. 
                    <template v-if="setupData.config.modules.rantomat.thresholdWorktimeRecordsDiff > 3600">
                        {{ dayjs.duration(setupData.config.modules.rantomat.thresholdWorktimeRecordsDiff, 'seconds').hours() }} h
                        {{ dayjs.duration(setupData.config.modules.rantomat.thresholdWorktimeRecordsDiff, 'seconds').minutes() }} m
                    </template>
                    <template v-else>
                        {{ parseInt(dayjs.duration(setupData.config.modules.rantomat.thresholdWorktimeRecordsDiff, 'seconds').asMinutes()) }} min
                    </template>
                </small>
            </div>
        </div>

        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Maximaldifferenz zwischen Arbeits- und Buchungszeiten:</strong> Der Schwellenwert für die Fehlerbewertung, wenn die Arbeitszeit die Buchungszeit überschreitet, zum Beispiel bei vergessener Beendigung eines Arbeitsgangs. Diese Zeit wird für positive Abweichungen berücksichtigt.</p>
            </div>
            <div class="w-1/3">
                <UFormGroup label="Max. pos. Arbeits- vs. Buchungszeit">
                    <UInput 
                        type="number"
                        min="0"
                        max="999999"
                        v-model="setupData.config.modules.rantomat.thresholdWorktimeRecordsDiffMax"
                        placeholder="Gib eine Zeit in Sek. ein..."
                        :ui="{ trailing: { padding: { 'xl': 'pe-24' } } }"
                    >
                        <template #trailing>Sekunden</template>
                    </UInput>
                </UFormGroup>
                <small class="block pt-2 opacity-60 font-bold">
                    Das sind ca. 
                    <template v-if="setupData.config.modules.rantomat.thresholdWorktimeRecordsDiffMax > 3600">
                        {{ dayjs.duration(setupData.config.modules.rantomat.thresholdWorktimeRecordsDiffMax, 'seconds').hours() }} h
                        {{ dayjs.duration(setupData.config.modules.rantomat.thresholdWorktimeRecordsDiffMax, 'seconds').minutes() }} m
                    </template>
                    <template v-else>
                        {{ parseInt(dayjs.duration(setupData.config.modules.rantomat.thresholdWorktimeRecordsDiffMax, 'seconds').asMinutes()) }} min
                    </template>
                </small>
            </div>
        </div>
    </div>

    <div v-if="setupData.config.timings" class="flex flex-col gap-4">
        <h2 class="sub-section-header">Ein paar Mindestwerte</h2>

        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Pause zu kurz:</strong> Die minimale Pausendauer, bei deren Unterschreitung eine Meldung ausgegeben werden kann. Dieser Wert dient nur der Anzeige und wird nicht in Berechnungen einbezogen.</p>
            </div>
            <div class="w-1/3">
                <UFormGroup label="Mindestpausenzeit">
                    <UInput 
                        type="number"
                        min="0"
                        max="999999"
                        v-model="setupData.config.modules.rantomat.thresholdBreakTooShort"
                        placeholder="Gib eine Zeit in Sek. ein..."
                        :ui="{ trailing: { padding: { 'xl': 'pe-24' } } }"
                    >
                        <template #trailing>Sekunden</template>
                    </UInput>
                </UFormGroup>
                <small class="block pt-2 opacity-60 font-bold">
                    Das sind ca. 
                    <template v-if="setupData.config.modules.rantomat.thresholdBreakTooShort > 3600">
                        {{ dayjs.duration(setupData.config.modules.rantomat.thresholdBreakTooShort, 'seconds').hours() }} h
                        {{ dayjs.duration(setupData.config.modules.rantomat.thresholdBreakTooShort, 'seconds').minutes() }} m
                    </template>
                    <template v-else>
                        {{ parseInt(dayjs.duration(setupData.config.modules.rantomat.thresholdBreakTooShort, 'seconds').asMinutes()) }} min
                    </template>
                </small>
            </div>
        </div>

        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Mindestarbeitszeit allgemein:</strong> Der Filterwert, um Datensätze auszuschließen, bei denen die Tagesarbeitszeit oder die korrigierte Tagesarbeitszeit unter diesem Wert liegt.</p>
            </div>
            <div class="w-1/3">
                <UFormGroup label="Mindestarbeitszeit allg.">
                    <UInput 
                        type="number"
                        min="0"
                        max="999999"
                        v-model="setupData.config.modules.rantomat.thresholdMinWorktime"
                        placeholder="Gib eine Zeit in Sek. ein..."
                        :ui="{ trailing: { padding: { 'xl': 'pe-24' } } }"
                    >
                        <template #trailing>Sekunden</template>
                    </UInput>
                </UFormGroup>
                <small class="block pt-2 opacity-60 font-bold">
                    Das sind ca. 
                    <template v-if="setupData.config.modules.rantomat.thresholdMinWorktime > 3600">
                        {{ dayjs.duration(setupData.config.modules.rantomat.thresholdMinWorktime, 'seconds').hours() }} h
                        {{ dayjs.duration(setupData.config.modules.rantomat.thresholdMinWorktime, 'seconds').minutes() }} m
                    </template>
                    <template v-else>
                        {{ parseInt(dayjs.duration(setupData.config.modules.rantomat.thresholdMinWorktime, 'seconds').asMinutes()) }} min
                    </template>
                </small>
            </div>
        </div>

        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Mindestbuchungszeit allgemein:</strong> Der Filterwert, um AP+ Buchungen auszuschließen, deren Dauer diesen Wert unterschreitet. Dadurch werden kurze Buchungen, die beispielsweise nur zur Fertigmeldung dienen, von den Berechnungen ausgenommen.</p>
            </div>
            <div class="w-1/3">
                <UFormGroup label="Mindestbuchungszeit allg.">
                    <UInput 
                        type="number"
                        min="0"
                        max="999999"
                        v-model="setupData.config.modules.rantomat.thresholdMinRecordDuration"
                        placeholder="Gib eine Zeit in Sek. ein..."
                        :ui="{ trailing: { padding: { 'xl': 'pe-24' } } }"
                    >
                        <template #trailing>Sekunden</template>
                    </UInput>
                </UFormGroup>
                <small class="block pt-2 opacity-60 font-bold">
                    Das sind ca. 
                    <template v-if="setupData.config.modules.rantomat.thresholdMinRecordDuration > 3600">
                        {{ dayjs.duration(setupData.config.modules.rantomat.thresholdMinRecordDuration, 'seconds').hours() }} h
                        {{ dayjs.duration(setupData.config.modules.rantomat.thresholdMinRecordDuration, 'seconds').minutes() }} m
                    </template>
                    <template v-else>
                        {{ parseInt(dayjs.duration(setupData.config.modules.rantomat.thresholdMinRecordDuration, 'seconds').asMinutes()) }} min
                    </template>
                </small>
            </div>
        </div>
    </div>

    <div v-if="setupData.config.timings" class="flex flex-col gap-4">
        <h2 class="sub-section-header">Vergangenheitsgedöns</h2>

        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Zu lesende Monatsanzahl:</strong> Die Anzahl der Monate, für die Rohdaten und Buchungsdatensätze aus dem Archiv sowie PZE-Buchungen aus der AP+ Datenbank analysiert werden.</p>
            </div>
            <div class="w-1/3">
                <UFormGroup label="Backend-Monatsanzahl">
                    <UInput 
                        type="number"
                        min="0"
                        max="9999"
                        v-model="setupData.config.modules.rantomat.parseNumOfMonth"
                        placeholder="Gib eine Zeit in Sek. ein..."
                        :ui="{ trailing: { padding: { 'xl': 'pe-24' } } }"
                    >
                        <template #trailing v-if="setupData.config.modules.rantomat.parseNumOfMonth !== 1">Monate</template>
                        <template #trailing v-else>Monat</template>
                    </UInput>
                </UFormGroup>
            </div>
        </div>

        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Anzahl der Tage im BACKEND:</strong> Der Zeitraum, der im Backend dargestellt wird, ist auf diese Anzahl von Tagen begrenzt. Der Nutzer sieht somit maximal diesen Zeitabschnitt in die Vergangenheit ab dem Zugriffszeitpunkt.</p>
            </div>
            <div class="w-1/3">
                <UFormGroup label="API-Tagesanzahl">
                    <UInput 
                        type="number"
                        min="0"
                        max="9999"
                        v-model="setupData.config.modules.rantomat.backendNumOfDays"
                        placeholder="Gib eine Zeit in Sek. ein..."
                        :ui="{ trailing: { padding: { 'xl': 'pe-16' } } }"
                    >
                        <template #trailing v-if="setupData.config.modules.rantomat.backendNumOfDays !== 1">Tage</template>
                        <template #trailing v-else>Tag</template>
                    </UInput>
                </UFormGroup>
            </div>
        </div>

        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Anzahl der Tage via API:</strong> Der maximale Zeitraum, der über die API abgefragt und ausgewertet werden kann. Der Nutzer sieht also bis zu diesen Anzahl von Tagen rückwirkend.</p>
            </div>
            <div class="w-1/3">
                <UFormGroup label="API-Tagesanzahl">
                    <UInput 
                        type="number"
                        min="0"
                        max="9999"
                        v-model="setupData.config.modules.rantomat.apiUserNumOfDays"
                        placeholder="Gib eine Zeit in Sek. ein..."
                        :ui="{ trailing: { padding: { 'xl': 'pe-16' } } }"
                    >
                        <template #trailing v-if="setupData.config.modules.rantomat.apiUserNumOfDays !== 1">Tage</template>
                        <template #trailing v-else>Tag</template>
                    </UInput>
                </UFormGroup>
            </div>
        </div>

        <div class="flex flex-row gap-4 p-4 hover:bg-indigo-400/10">
            <div class="w-2/3">
                <p><strong class="uppercase">Maximalalter nicht korrigierter Fehler:</strong> Gibt an, ab welchem Alter von Buchungen eine Benachrichtigung <code class="bg-indigo-600 text-white font-bold text-sm p-1 pb-0.5 rounded-md">rantomat_notification_supervisor_maxage</code> an den nächsthöheren Vorgesetzten im Unternehmen gesendet wird, wenn fehlerhafte Buchungen erkannt werden.</p>
            </div>
            <div class="w-1/3">
                <UFormGroup label="Max. Alter">
                    <UInput 
                        type="number"
                        min="0"
                        max="9999"
                        v-model="setupData.config.modules.rantomat.maxAgeError"
                        placeholder="Gib eine Zeit in Sek. ein..."
                        :ui="{ trailing: { padding: { 'xl': 'pe-16' } } }"
                    >
                        <template #trailing v-if="setupData.config.modules.rantomat.maxAgeError !== 1">Tage</template>
                        <template #trailing v-else>Tag</template>
                    </UInput>
                </UFormGroup>
            </div>
        </div>

    </div>
</template>
<script setup>
    import { Icon } from '@iconify/vue';

    import dayjs from 'dayjs'
    import duration from 'dayjs/plugin/duration'
    dayjs.extend(duration)
    import utc from 'dayjs/plugin/utc.js'
    dayjs.extend(utc)
    import timezone from 'dayjs/plugin/timezone.js'
    dayjs.extend(timezone)
    import isoWeek from 'dayjs/plugin/isoWeek.js'
    dayjs.extend(isoWeek)
    console.log('dayjs', dayjs().isoWeekday(1).format('dddd'))
    // import 'dayjs/locale/de'
    dayjs.locale('de')

    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const { eijnsJWT, headers } = await useGlobals()
    const appStore = useAppStore()
    const { globals } = storeToRefs(appStore)

    const route = useRoute();
    const router = useRouter();

    const props = defineProps({
        setupData: {
            type: Object,
        },
    })

    const emit = defineEmits(['commit'])

    if (!Array.isArray(props.setupData.config.modules.rantomat.sendHours)) {
        props.setupData.config.modules.rantomat.sendHours = []
    }

    if (!Array.isArray(props.setupData.config.modules.rantomat.sendWeekdays)) {
        props.setupData.config.modules.rantomat.sendWeekdays = []
    }

    watch(() => props.setupData.config.modules.rantomat.forceReparseData, (val) => {

        if (!val) {
            props.setupData.config.modules.rantomat.forceResendNotification = false
        }
    })

    const sendHours = ref(props.setupData.config.modules.rantomat.sendHours.map(e => dayjs().utc().hour(e).minute(0).second(0).tz(localTimezone).hour()))
    watch(sendHours, (val) => {
        const res = []

        for (const sh of val) {
            res.push(dayjs().hour(sh).minute(0).second(0).tz(localTimezone).utc().hour())
        }

        // console.log(res)
        props.setupData.config.modules.rantomat.sendHours = res
    })


    const userList = ref()
    const userListSearch = computed((q) => {
        console.log('userListSearch', q)
        return userList.value//.find(e => e.fullname.indexOf(q))
    })

    const fetchUserList = await useFetch(
        '/api/user/', 
        { 
            headers: {
                ...headers,
                'x-docs-per-page': 99999,
                //'x-get-minimal': true,
            },
            server: false,
            //watch: [ headers ],
            immediate: true,
            onResponse({ request, response, options }) {
                //console.log('onResponse', response);

                if (!response.ok) {
                    this.onResponseError({ request, response, options })
                    return
                }

                userList.value = response?._data?.data
                userList.value.forEach(e => {
                    e.fullname = `${JSON.stringify(e.personal)} ${e.username} ${e.email}`
                })
            },
            onResponseError({ request, response, options }) {
                console.log('fetchUserList.onResponseError', response);
                appStore.error(null, {
                    ...response._data.error.value,
                    description: 'Die Benutzerdatensätze konnte nicht gelesen werden',
                    solution: 'Gib jemandem Bescheid, der sich damit auskennt',
                })
            },
        }
    )

</script>