export default {
    length: [
        { id: 'meter', name: 'Meter', symbol: 'm' },
        //{ id: 'kilometer', name: 'Kilometer', symbol: 'km' },
        //{ id: 'centimeter', name: 'Zentimeter', symbol: 'cm' },
        //{ id: 'millimeter', name: 'Millimeter', symbol: 'mm' },
        { id: 'foot', name: 'Fuß', symbol: 'ft' },
        { id: 'inch', name: 'Zoll', symbol: 'in' },
    ],
    weight: [
        { id: 'kilogram', name: 'Kilogramm', symbol: 'kg' },
        //{ id: 'gram', name: 'Gramm', symbol: 'g' },
        //{ id: 'tonne', name: 'Tonne', symbol: 't' },
        { id: 'pound', name: 'Pfund', symbol: 'lb' },
        { id: 'ounce', name: 'Unze', symbol: 'oz' },
    ],
    temperature: [
        { id: 'celsius', name: 'Celsius', symbol: '°C' },
        { id: 'fahrenheit', name: 'Fahrenheit', symbol: '°F' },
        { id: 'kelvin', name: 'Kelvin', symbol: 'K' },
    ],
    quantities: [
        { id: 'piece', name: 'Stück', symbol: 'Stk.' },
        { id: 'kilogram', name: 'Kilogramm', symbol: 'kg' },
        { id: 'liter', name: 'Liter', symbol: 'L' },
    ],
    duration: [
        {
            id: 'year',
            name: 'Jahr',
            namePlural: 'Jahre',
        }, 
        {
            id: 'month',
            name: 'Monat',
            namePlural: 'Monate',
        }, 
        {
            id: 'week',
            name: 'Woche',
            namePlural: 'Wochen',
        }, 
        {
            id: 'day',
            name: 'Tag',
            namePlural: 'Tage',
        }, 
        {
            id: 'hour',
            name: 'Stunde',
            namePlural: 'Stunden',
        }, 
        {
            id: 'minute',
            name: 'Minute',
            namePlural: 'Minuten',
        }, 
        {
            id: 'second',
            name: 'Sekunde',
            namePlural: 'Sekunden',
        }
    ]
};
