// plugins/fetchErrorHandler.js
// export default defineNuxtPlugin((nuxtApp) => {
//     nuxtApp.hook('app:mounted', () => {
//         const originalFetch = globalThis.$fetch || fetch;

//         globalThis.$fetch = async (url, options) => {
//             try {
//                 // Führe die ursprüngliche fetch-Anfrage aus
//                 const response = await originalFetch(url, options);
//                 console.log("!response.ok:", response);

//                 // Optional: Überprüfe den Status der Antwort (z. B. Status 200-299 für Erfolg)
//                 if (!response.ok) {
//                     throw new Error(response);

//                     // throw createError({
//                     //     statusCode: response._data.statusCode || '500', 
//                     //     statusMessage: response._data.statusMessage || 'Internal Server Error', 
//                     //     // data: {
//                     //     //     desc: 'Zustimmung konnte nicht gelöscht werden',
//                     //     //     api: request,
//                     //     //     response: 'response._data',
//                     //     // }
//                     // })
//                 }

//                 return response;
//             } catch (error) {
//                 // Zentrale Fehlerbehandlung
//                 console.error("Fetch error:", error);
//                 // Optional: Benutzerdefinierte Fehlernachricht anzeigen, z. B. über Toast-Benachrichtigung
//                 // oder Sentry / ein anderes Monitoring-Tool verwenden
//                 return { error: true, message: error.message };
//             }
//         };
//     });
// });
