//useEvent.js

export default function useEvent(eventName, eventHandler, eventOptions = {}) {
    console.log(`useEvent: event '${eventName}' registered`)
    const eventTarget = ref(window);    // default target is window

    onMounted(() => {
        eventTarget.value.addEventListener(eventName, eventHandler, eventOptions);
    });

    onBeforeUnmount(() => {
        eventTarget.value.removeEventListener(eventName, eventHandler, eventOptions);
    });

    // Allow the user to change the target if needed
    const setEventTarget = (newTarget) => {
        eventTarget.value = newTarget;
    };

    return { setEventTarget };
}
